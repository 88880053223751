export default `
{{customerId}} / {{trailerPlate}} arrived at Container Change in {{currentLocation}}



{{customerId}} / {{trailerPlate}} just arrived at Container Change in {{currentLocation}}

Time IN: {{currentArrivalTime}}

{{timezoneOffsetHoursWithCest}}{{waitingRateApplies}}

Best Regards,
{{userFullName}}
Boekestijn Transport Service
{{userEmailSignature}}
`;