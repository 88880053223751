import { observable, computed } from 'mobx';
import { Model, Store, Casts, FileField } from 'store/Base';
import { Driver } from './Driver';
import { User } from './User';
import { storeWithHistory } from './History';
import { showSaveNotification, showNotification } from '@code-yellow/spider';
import { RejectionReason } from './RejectionReason';
import { FuelCost } from './FuelCost';
import { RoadCost } from './RoadCost';
import { CostProduct } from './CostProduct';
import { DocumentStore } from './Document';
import { SimpledTransaction } from './SimpledTransaction';
import { Truck } from './Truck';
import { CurrencyStore } from './Currency';
import { SERVER_DATE_FORMAT } from 'helpers';

export const SIMPLED = 'simpledcard';
export const PRIVATE = 'private card';
export const CASH = 'cash';


export class Declaration extends Model {
    static backendResourceName = 'declaration';

    @observable id = null;
    @observable paymentMethod = PRIVATE;
    @observable approvedAt = null;
    @observable rejectedAt = null;
    @observable rejectedOrApproved = false;
    @observable completedAt = null;
    @observable createdAt = null;
    @observable updatedAt = null;
    @observable documentNumber = '';
    @observable documentScan = new FileField({ name: 'documentScan' });
    @observable remark = '';
    @observable officeRemark = '';
    @observable transactionDatetime = null;
    @observable deleted = false;
    @observable isSuggested = false;
    @observable currency = null;
    @observable quantity = null;
    @observable totalQuantity = null;
    @observable isOfficeAction = true;
    @observable updateRoadOrFuelCost = true;
    @observable isDraft = false;
    @observable quantityFc = null;
    @observable totalQuantityFc = null;
    @observable blockedAmount = null;
    @observable productDescription = null;
    @observable groupUuid = null;
    @observable creator = null;
    @observable aiAvailable = false;
    @observable aiCostProductSelected = false;
    @observable oldQuantity = null;
    @observable aiQuantitySelected = false;
    @observable aiDateMatch = false;

    async exchangeMoney(date, currency) {
        try {
            const dayBefore = date.clone().subtract(1, 'day');
            let days = [date.format(SERVER_DATE_FORMAT), dayBefore.format(SERVER_DATE_FORMAT)];

            const currencyStore = new CurrencyStore({
                params: {
                    order_by: '-created_at',
                    '.type': currency,
                    '.rate_date:in': days.join(',')
                },
            });

            await currencyStore.fetch();

            if (currencyStore.length !== 0) {
                const rate = parseFloat(currencyStore.at(0).rate)

                if (!this.roadCost.grossFc) {
                    this.roadCost.setInput('grossFc', rate * this.quantity)
                }

                if (!this.roadCost.netFc) {
                    this.roadCost.setInput('netFc', rate * this.quantity)
                }

                if (!this.fuelCost.grossFc) {
                    this.fuelCost.setInput('grossFc', rate * this.quantity)
                }

                if (!this.fuelCost.netFc) {
                    this.fuelCost.setInput('netFc', rate * this.quantity)
                }


                if (!this.roadCost.grossOc) {
                    this.roadCost.setInput('grossOc',  this.quantityFc / rate)
                }

                if (!this.roadCost.netOc) {
                    this.roadCost.setInput('netOc',  this.quantityFc / rate)
                }

                if (!this.fuelCost.grossOc) {
                    this.fuelCost.setInput('grossOc',  this.quantityFc / rate)
                }

                if (!this.fuelCost.netOc) {
                    this.fuelCost.setInput('netOc',  this.quantityFc / rate)
                }

                return currencyStore
            }

        } catch (error) {
            console.error('An error occurred:', error)
            throw error;
        }

    }

    async handleExchange(date, currency) {
        const currencyStore = await this.exchangeMoney(date, currency);
        return currencyStore
    }

    deleteDeclaration() {
        return this.api.post(`${this.url}delete_declaration/`)
        .then(showSaveNotification);
    }

    approveDeclaration() {
        return this.api.post(`${this.url}approve_declaration/`)
        .then((res)=> {
            if (res && res.error) {
                showNotification({
                    key: res.error,
                    type: 'error',
                    dismissAfter: 8000,
                    message: res.error,
                });
            } else {
                showSaveNotification()
            }
        });

    }

    unApproveDeclaration() {
        return this.api.post(`${this.url}unapprove_declaration/`)
        .then(showSaveNotification);
    }

    rejectDeclaration() {
        return this.api.post(`${this.url}reject_declaration/`)
            .then(showSaveNotification);

    }

    unRejectDeclaration() {
        return this.api.post(`${this.url}unreject_declaration/`)
        .then(showSaveNotification);
    }

    saveRoadCostDeclaration() {
        let relations = [
            'roadCost.truck.entity',
            'roadCost.entity',
            'roadCost.truck',
            'roadCost.costProduct',
            'roadCost.ledgerCost.codeReports',
            'roadCost.activity.location',
            //'roadCost.activity.allocation.contract', // removed because this caused driverCare/manager/declarations.T38635.cy.js to crash
            'roadCost.driver',
            'roadCost.location',
            'roadCost.costInvoice',
        ]

        if (this.oldCostProduct.id) {
            this.setInput('oldCostProduct', this.oldCostProduct);
            relations.push('oldCostProduct')
        }

        return this.save({
            onlyChanges: true,
            relations: relations,
        })
        .then(() =>  Promise.all([].concat(
            this.documentScan ? this.documentScan.save(this.url) : null
        )))
        .then(showSaveNotification)
    }

    saveFuelCostDeclaration() {
        let relations = [
            'fuelCost.truck.entity',
            'fuelCost.entity',
            'fuelCost.truck',
            'fuelCost.costProduct',
            'fuelCost.activity.location',
            'fuelCost.location',
            'fuelCost.driver',
            'fuelCost.costInvoice',
            'fuelCost.ledgerCost.codeReports',
            //'fuelCost.activity.allocation.contract', // removed because this caused driverCare/manager/declarations.T38635.cy.js to crash
            // 'fuelCost.activity.allocation.contract',
        ]

        if (this.oldCostProduct.id) {
            this.setInput('oldCostProduct', this.oldCostProduct);
            relations.push('oldCostProduct')
        }
        return this.save({
            onlyChanges: true,
            relations: relations,
        })
        .then(() =>  Promise.all([].concat(
            this.documentScan ? this.documentScan.save(this.url) : null
        )))
        .then(showSaveNotification)
    }

    hasMonthPassed(targetDateString) {
        const currentDate = new Date();
        const targetDate = new Date(targetDateString);

        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth();

        const targetYear = targetDate.getFullYear();
        const targetMonth = targetDate.getMonth();

        if (targetYear < currentYear) {
            return true;
        } else if (targetYear === currentYear && targetMonth < currentMonth) {
            return true;
        } else {
            return false;
        }
    }

    casts() {
        return {
            createdAt: Casts.datetime,
            updatedAt: Casts.datetime,
            approvedAt: Casts.datetime,
            completedAt: Casts.datetime,
            transactionDatetime: Casts.datetime,
            documentScan: Casts.file,
        };
    }

    relations() {
        return {
            driver: Driver,
            truck: Truck,
            approvedBy: User,
            rejectedBy: User,
            fuelCost: FuelCost,
            roadCost: RoadCost,
            rejectionReason: RejectionReason,
            costProduct: CostProduct,
            oldCostProduct: CostProduct,
            documents: DocumentStore,
            simpledTransaction: SimpledTransaction,
        };
    }

    @computed
    get documentsToDisplay() {
        if (this.documents) {
            return this.documents.filter(doc => (doc.status !== 'scan requested'))
        }

        return [];
    }

    checkAiResults() {
        return this.api.get(this.url + 'check_ai_results/',  {});
    }
}

export class DeclarationStore extends Store {
    Model = Declaration;
    static backendResourceName = 'declaration';
}

export class HistoryDeclarationStore extends storeWithHistory(DeclarationStore){};
