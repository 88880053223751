import { observable } from 'mobx';
import { Model, Store } from 'store/Base';
import { Invoice } from './Invoice';

export class Csa extends Model {
    static backendResourceName = 'csa';

    @observable id = null;
    @observable instruction = {};

    relations() {
        return {
            invoice: Invoice
        };
    }
}

export class CsaStore extends Store {
    Model = Csa;
    static backendResourceName = 'csa';
}
