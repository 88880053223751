import React from 'react';
import Component from 'component/Component';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { observable, action } from 'mobx';
import subscribe from 'mixin/subscribe';
import { parseBackendErrorMessages } from 'helpers';
import Details, { DetailsRow, DetailsCol } from 'component/Compact/Details';
import { Row, Col, SuperText, Subheading, FormField, TextInput, TextArea, NumberInput, SingleDatePicker, TimeInput, Button } from 're-cy-cle';
import { Activity, ActivityStore, TYPE_UNLOAD, TYPE_TUV as ACTIVITY_TYPE_TUV, CONDITION_TEMPERATURE, TYPE_TRAILER_CHANGE, TYPE_CONTAINER_CHANGE } from 'store/Activity';
import { Truck } from 'store/Truck';
import ActivityTruckMap, { TopRight } from '../ActivityDetails/TruckMap';
import { MapContainer } from 'component/Location/Map';
import ActivityFinalizeDocument from '../ActivityFinalize/Document';
import ActivityFinalizeOtherCosts from '../ActivityFinalize/OtherCosts';
import ActivityFinalizeTruck from '../ActivityFinalize/Truck';
import ActivityFinalizeStops from '../ActivityFinalize/Stops';
import SaveButton from 'component/form/SaveButton';
import { DATETIME_FORMAT, getMoneyForUser, setMoneyForBackend } from 'helpers';
import { getMoneyInputProps  } from '@code-yellow/spider';
import moment from 'moment-timezone';
import ModalChangeKm from 'screen/ModalChangeKm';
import ConfirmModal from 'component/ConfirmModal';
import { fetchMarkers } from 'store/Location';
import { TYPE_TUV as DOCUMENT_TYPE_TUV, TYPE_REGISTRATION_DOCUMENT as DOCUMENT_TYPE_REGISTRATION_DOCUMENT } from 'store/Document';
import { DOCUMENTS_MOMENT_WHEN_AFTER_FINALIZE } from 'store/Contract';
import { CancelButton  } from '@code-yellow/spider';
import { TruckPositionStore } from 'store/TruckPosition';
import { BottomDivider, ButtonWrapper, AncientRefreshButton, RightDivider } from '@code-yellow/spider';
import { AncientFormRadioButtons as FormRadioButtons, AncientFormTextInput as FormTextInput } from '@code-yellow/spider';
import styled from 'styled-components';
import ComposeEmail from 'component/ComposeEmail';
import { ContactStore, TYPE_ADMINISTRATION } from 'store/Contact';
import { Model } from 'store/Base';
import { DATE_MINI_FORMAT, DATE_RANGE_FORMAT } from 'helpers';
import { FormDatetimePicker, FormNumberInput  } from '@code-yellow/spider';
import ActivityFinalizeInvoices from '../ActivityFinalize/Invoices';
import ActivityFinalizeWaivers from '../ActivityFinalize/Waivers';
import ActivityFinalizeCosts from '../ActivityFinalize/Costs';
import ActivityHeader from 'container/Invoice/ActivityEdit/ActivityHeader';
import { Table, TBody } from 'component/Invoice/Table';
import ActivityTotals  from 'container/Invoice/ActivityEdit/Totals';
import { Table as TableS, Confirm, Form, Dimmer, Loader } from 'semantic-ui-react';
import { showSaveNotification } from '@code-yellow/spider';
import { HeaderRight  } from '@code-yellow/spider';
import { IconButton  } from '@code-yellow/spider';
import { CONTEXT_TYPE_ACTIVITY } from 'store/BEPTicket';
import BEPContextButtons from 'component/BEPTicket/BEPContextButtons';
import { Td } from 'component/Invoice/Table';
import { urlEncodeObject  } from '@code-yellow/spider';
import ActivityInvoiceStatus from 'component/Activity/InvoiceStatus';
import OrderConfirmationCards from 'component/Activity/OrderConfirmationCards';
import { COLOR_INVOICE_WAIVED, COLOR_INVOICE_CORRECTED, COLOR_INVOICE_INVOICED, COLOR_INVOICE_DISPUTED, COLOR_INVOICE_COSTS } from 'styles';
import { isEmpty, uniq, template, get, omit } from 'lodash';
import { hasPermission } from 'helpers/permission';
import TrackedEquipmentTable from './TrackedEquipmentTable';
import { TRACKING_TARGET_TRAILER, TRACKING_TARGET_ALLOCATION } from 'store/TrackedEquipment';
import { MessageStore } from 'store/Message';
import { STATUS_APPLIED } from 'store/EquipmentChange';
import { getCurrencySymbolFromCode } from 'helpers';

const RelativeMapContainer = styled(MapContainer)`
    position: relative;
    height: 50%;
`;

const HalfRelativeMapContainer = styled(RelativeMapContainer)`
    height: 50%;
`;

function formatDatetime(date) {
    return date ? date.format(DATETIME_FORMAT) : '-';
}

const StyledComposeEmail = styled(ComposeEmail)`
    margin-top: 12px;
`;

const Fragment = React.Fragment;
const UnstyledA = styled.a`
    color: inherit;
    text-decoration: inherit;
`;

const UppercaseHeaderRight = styled(HeaderRight)`
    .ui.header {
        text-transform: uppercase;
    }
`;

const DateTimePicker = observer(({ activity, attr, disabled, onChange }) => {
    return (
        <FormField
            label={t(`activity.field.${attr}.label`)}
            error={activity.backendValidationErrors[attr]}
        >
            <Row>
                <Col xs={7}>
                    <SingleDatePicker
                        name={attr}
                        value={activity[attr]}
                        disabled={disabled}
                        onChange={onChange}
                    />
                </Col>
                <Col xs={5}>
                    <TimeInput
                        name={attr}
                        value={activity[attr]}
                        disabled={disabled}
                        onChange={onChange}
                    />
                </Col>
            </Row>
        </FormField>
    );
});

// Fake model to handle sending emails. {email-almost-duplication}
class Email extends Model {
    @observable id = null;
    @observable recipients = [];
    @observable subject = '';
    @observable content = '';

    formatTimeCarefully(obj, prop) {
        if (obj && obj[prop]) {
            return obj[prop].format(DATE_MINI_FORMAT);
        }
        return '??';
    }

    @action
    generate(activity, contract, allocation) {
        const templateData = {
            city: activity.location.city,
            finishedDatetime: this.formatTimeCarefully(
                activity,
                'finishedDatetime'
            ),
            orderReference: activity.orderReference,
            documentRejectUrl: activity.documentRejectUrl,
            customerCode: allocation.customerCode,
        };

        this.subject = template(contract.activityFinalizeEmailSubject)(templateData);
        this.content = template(contract.activityFinalizeEmailContent)(templateData);
    }
}

DateTimePicker.propTypes = {
    activity: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    attr: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
};

@observer
class CustomActivityTotals extends ActivityTotals {
    renderColumns() {
        const { borders } = this.props;

        return (
            <React.Fragment>
                {super.renderColumns()}
                <Td borders={borders}>{this.props.sign}</Td>
            </React.Fragment>
        );
    }
}

@observer
export default class OverviewDetailsActivityFinalize extends subscribe(Component) {
    static propTypes = {
        allocation: PropTypes.object.isRequired,
        activityId: PropTypes.number.isRequired,
        truckPositionStore: PropTypes.object.isRequired,
        skipAfterActivityFetch: PropTypes.bool, // Special flag to disable fetching extra stuff. Specifically added for unit testing.
        viewStore: PropTypes.object.isRequired,
        showMap: PropTypes.bool,
        showCustomFinalizedModule: PropTypes.bool,
        showDocumentsEmail: PropTypes.bool, // Only set true, if showMap also is true.
        hideSkipButton: PropTypes.bool,
        hideRegenerateTemperaturePrintButton: PropTypes.bool,
        hideSaveButton: PropTypes.bool,
        hideUnfinalizeButton: PropTypes.bool,
        afterSave: PropTypes.func.isRequired,
        afterLoadActivity: PropTypes.func,
    };

    @observable activity = null;
    @observable isLoading = true;
    @observable activityRoute = [];
    @observable truckRoute = [];
    @observable activityMarkers = [];
    @observable truckPositionStore = new TruckPositionStore({
        limit: null,
    });
    // {copy-pasta-previous-activity-store}
    @observable previousActivityStore = new ActivityStore({
        relations: ['equipmentChanges.equipmentType', 'trailer.equipments.equipmentType'],
        limit: false,
    });
    @observable email = new Email();
    @observable contactStore = new ContactStore({ limit: null });

    @observable documentErrors = []
    @observable mouseOverTruckPosition = null;

    @observable showConfirmWaitingCostsModal = false;
    @observable waitingCostsConfirmedByUser = false;

    @observable lastFinishedActivityStore = new ActivityStore();
    @observable driverPoiStore = new MessageStore({ limit: 20 });

    @observable _currencySymbol = '€';

    disablePlannedKm = () => {
        if (this.hasPermission(['activity.tweak_activity:all'])) {
            if (!!this.activity.finalizedAt) {
                return true
            }
            return false
        }
        return true
    }

    disableGivenKm = () => {
        if (this.hasPermission(['activity.tweak_activity:all'])) {
            if (!!this.activity.finalizedAt) {
                return true
            }
            return false
        }
        return true
    }

    constructor(props) {
        super(props);

        const userCanViewInvoices = hasPermission('invoice.manage_invoice:all', 'invoice.manage_invoice:customer_service', 'invoice.view_invoice:all');
        this.userCanViewInvoices = userCanViewInvoices;
        const activityRelations = [
            'location',
            'nextTrailer', // For displaying dropoff or pickup.
            'nextContainer1',
            'nextContainer2',
            'container1',
            'container2',
            'documents.activity.location',
            'documents.truck.currentAssignment.driver1',
            'documents.truck.currentAssignment.driver2',
            'documents.truck',
            'orderConfirmations',
            'assignment.truck',
            'assignment.driver1',
            'assignment.driver2',
            'previousActivity.location',
            'previousStops.assignment.truck',
            'previousStops.assignment.driver1',
            'previousStops.location',
            'otherCosts',

            // {copy-pasta-tariff-relations}
            'fixedRateDays',
            'allocation.contract.tariffs.vatRule',
            'allocation.contract.tariffs.contract.customer.contacts',
            'allocation.contract.tariffs.contract.entity',
            'allocation.contract.tariffs.kmRates',
            'allocation.contract.tariffs.fixedRates',
            'allocation.contract.tariffs.weekendRates',
            'allocation.contract.tariffs.waitingRates',
            'allocation.contract.tariffs.secondDriverRates',

            'allocation.contract.customer',
            'allocation.contract.entity',
            'proposedActivity',

            // equipment stuff
            'allocation.contract.trackedEquipments.equipmentType',
            'allocation.equipments.equipmentType',
            'equipmentChanges.equipmentType',
            'trailer.equipments.equipmentType',
        ];

        const invoiceRelations = [
            'invoiceLineItems.invoiceLine.invoice.lines.items',
            'invoiceLineItems.invoiceLine.items.activity',
            'invoiceLineItems.invoiceLine.invoice.corrections',
            'invoiceLineItems.invoiceLine.disputes.corrections.invoice.currency',
            'invoiceLineItems.invoiceLine.disputes.waivers.createdBy',
            'waiverItems',
            'invoiceLineCorrectionItems.invoiceLineCorrection',

            'costItems.cost.createdBy',
        ];

        this.activity = new class extends Activity {
            buildFetchData(options) {
                const data = super.buildFetchData(options);

                if (!userCanViewInvoices) {
                    data.with = activityRelations.map(this.constructor.toBackendAttrKey).join(',');
                }

                return data;
            }
        }(null, {
            relations: [...activityRelations, ...invoiceRelations],
        });

        window.activity = this.activity;
    }

    UNSAFE_componentWillMount() {
        this.activity.id = this.props.activityId;
        this.activity.where = this.props.activityId;
    }

    componentDidMount() {
        const { allocation, afterLoadActivity } = this.props;

        this.activity.setFetchParams({
            include_invoiced: true,
            include_invoice_summary: true,
        });

        this.activity.fetch(this.cancelTokenRequestOptions()).then(res => {
            if (afterLoadActivity) {
                afterLoadActivity(this.activity);
            }

            if (this.activity) {
                this._currencySymbol = getCurrencySymbolFromCode(this.activity.allocation.contract.fcCode)
                if (allocation) {
                    this.email.generate(this.activity, allocation.contract, allocation);
                }

                this.calculateExpectedWaitCosts();
            }

            this.initSubscriptions();

            this.afterActivityFetch();
        }).then(() => {
            this.contactStore.params = {
                '.customer': this.activity.allocation.contract.customer.id,
                '.contracts': this.activity.allocation.contract.id,
                '.email:not': '', // hide contacts with empty email
            };
            this.contactStore.fetch(this.cancelTokenRequestOptions()).then(() => {
                // if teams integration is enabled we want see fake contact in recipients field
                if (allocation.contract.activateTeams) {
                    this.contactStore.add({
                        email: 'teams@boekestijntransport.com',
                        name: 'TEAMS',
                        types: [TYPE_ADMINISTRATION]
                    });
                }

                const dayshiftContacts = this.contactStore
                    .filter(c => c.types.includes(TYPE_ADMINISTRATION))
                    .map(c => c.email);
                this.email.recipients.replace(dayshiftContacts);
            }).catch(() => {}); // Unit tests fail otherwise?
        }).then(() => {
            this.isLoading = false;
        });
    }

    afterActivityFetch = () => {
        this.determineExtraRequiredDocuments();
        this.shouldSendDocuments();

        // Special hack for unit tests.
        if (this.props.skipAfterActivityFetch) {
            return;
        }

        // fetch last finished activity to check if TrackedEquipmentTable
        // should be disabled/enabled for editing. See T26992
        this.lastFinishedActivityStore.params = {
            '.allocation': this.activity.allocation.id,
            '.status': 'finished',
            '.deleted': false,
            'order_by': '-ordered_arrival_datetime_from',
            limit: 1
        }
        this.lastFinishedActivityStore.fetch();

        this.activity.calculateDetourKms();
        fetchMarkers({
            activity: this.activity,
            activityMarkers: this.activityMarkers,
            activityRoute: this.activityRoute,
        }, this.cancelTokenRequestOptions());

        this.driverPoiStore.params = {
            '.job_uuid': this.activity.uuid,
            '.type': 'poi',
        }
        this.driverPoiStore.fetch();

        // {copy-pasta-previous-activity-store}
        this.previousActivityStore.params = {
            'activity.equipment_changes.status:not': STATUS_APPLIED,
            '.allocation': this.activity.allocation.id,
            '.ordered_arrival_datetime_from:lt': this.activity.orderedArrivalDatetimeFrom
                .clone()
                .utc()
                .format(DATE_RANGE_FORMAT),
            '.id:not': this.activity.id,
            order_by: 'ordered_arrival_datetime_from',
        };
        this.previousActivityStore.fetch();

        // Don't show route when there is no truck or the start km is weird
        // T16730
        if (!this.activity.assignment.truck.id || this.activity.startKmIncludingStops === 0) {
            return;
        }

        // Fetch driven route. We do this based on odometer instead of
        // measured_at, since after testing it gives the best results.
        this.truckPositionStore.params = {
            '.truck': this.activity.assignment.truck.id,
            '.odometer:gte': this.activity.startKmIncludingStops,
            '.odometer:lte': this.activity.endKm,
            limit: (this.activity.endKm) ? 'none' : 500,
        };

        this.truckPositionStore.fetch(this.cancelTokenRequestOptions());
    };

    initSubscriptions = () => {
        this.subscriptions.push(
            this.subscribe(
                { target: 'activity-update-kms', id: this.activity.id },
                message => {
                    // Simply update given / planned km, so dispatchers can
                    // click on save button. See T21243.
                    this.activity.parse(message.data);
                }
            ),

            this.subscribe({
                    'target': 'document-update',
                    'activity': this.activity.id
                }, data => {
                    var document = this.activity.documents.get(data.data.id);
                    if (!document) {
                        document = this.activity.documents.add(data.data);
                    }
                    document.fetchForceFileRefresh();
                }
            ),

            this.subscribe({
                    'target': 'document-create',
                    'activity': this.activity.id
                }, data => {
                    var document = this.activity.documents.get(data.data.id);
                    if (!document && data.data.deleted === false) {
                        document = this.activity.documents.add(data.data)
                    }
                    document.fetch();
                }
            )
        );
    }

    @action
    calculateExpectedWaitCosts = () => {
        const tariff = this.activity.allocation.contract.currentTariff;

        if (tariff) {
            const amount = tariff.calcWaitingRateAmount(this.activity);

            this.activity.setInput('calculatedWaitingAmount', amount);
        }

        if (this.activity.calculatedWaitingAmount === 0 || this.activity.preliminaryWaitingAmount > 0) {
            this.waitingCostsConfirmedByUser = true;
        }
    };

    hasPermission = perms => {
        if (this.props.viewStore.currentUser) {
            return this.props.viewStore.currentUser.hasPermission(perms);
        } else {
            return false
        }
    };

    handleChange = (name, value) => {
        if (['preliminaryWaitingAmount', 'calculatedWaitingAmount'].includes(name)) {
            value = setMoneyForBackend(value);
        }
        this.activity.setInput(name, value);
        this.calculateExpectedWaitCosts();
    };

    handleCancel = () => {

        // This will do for now
        this.props.afterSave();
    };

    showModalChangeEndKm = (activity, truck, truckPosition) => {
        this.props.viewStore.setModal({
            type: 'end',
            render: ModalChangeKm,
            allocation: this.activity.allocation,
            truckPosition: truckPosition,
            onClick: () => {
                this.truckPositionStore.params['.odometer:lte'] = this.activity.endKm;
                this.truckPositionStore.fetch();
                this.forceUpdate();
            },
            activity,
            truck: truck,
        });
    };

    afterSave = () => {

        this.props.viewStore.showSaveNotification();
        if (this.props.afterSave) {this.props.afterSave(this.activity)};
    };

    _save = () => {
        const relations = ['documents', 'otherCosts', 'orderConfirmations'];

        return this.activity
            .save({
                relations,
                onlyChanges: true,
                mapData: data => omit(data, 'equipment_changes'),
            })
            .then(() => {
                // `previousStops` is not a real relation in the backend, so we hack around it.
                return this.activity.previousStops.saveAllHack({
                    onlyChanges: true,
                });
            });
    };

    save = () => {
        return this._save().then(() => this.afterSave());
    };

    saveFinalizing = () => {
        const tariff = this.activity.allocation.contract.currentTariff;

        this.activity.setInput('totalActivityDrivenKm', this.activity.drivenKmIncludingStops);

        if (tariff) {
            this.activity.setPreliminaryAmounts(tariff);
        }

        this._isLoading = true;
        this._save()
            .then(async() => {
                let res = await this.activity.finalize(this.email);
                if(res.data['teams_response']) {
                    if (res.data['teams_response']['send_documents_to_teams'] === false) {
                        this.props.viewStore.showNotification({
                            key: 'teams_response',
                            type: 'error',
                            dismissAfter: 4000,
                            message: 'You are not assigned to this allocation, so update will not be sent to the Teams Channel',
                        });
                    }
                }
            })
            .then(() => this._isLoading = false)
            .then(() => this.afterSave())
            .catch(response => {
                this._isLoading = false;
                const messages = parseBackendErrorMessages(get(response, 'response.data.errors') || get(response, 'response.response.data.errors'));

                messages.forEach(message => {
                    this.props.viewStore.showNotification({
                        key: message,
                        type: 'error',
                        dismissAfter: 4000,
                        message,
                    });
                });
            });
    };

    handleChangeKm = (name, value) => {
        const data = parseInt(value)
        this.activity.setInput(name, data)
    }

    clearKms = () => {
        const activity = this.activity;

        return activity.clearKms().then(() => {
            this.props.viewStore.showSaveNotification();
        });
    };

    onDocumentApprove = () => {
        this.shouldSendDocuments();
    };

    determineExtraRequiredDocuments() {
        // The backend will add tuv to required documents if there's a
        // TUV stop, but that happens upon finalizing.  We do it here
        // because we don't want the user to realise this _after_ saving.
        if (this.activity.previousStops.find((stop) => stop.type === ACTIVITY_TYPE_TUV) &&
            !this.activity.requiredDocuments.includes(DOCUMENT_TYPE_TUV)) {
            this.activity.setInput('requiredDocuments', this.activity.requiredDocuments.concat([DOCUMENT_TYPE_TUV]));
            this.activity.setInput('requiredDocuments', this.activity.requiredDocuments.concat([DOCUMENT_TYPE_REGISTRATION_DOCUMENT]));
        }
    };

    shouldSendDocuments() {
        if (this.activity.type === TYPE_UNLOAD && this.activity.allocation.contract.documentsMomentWhen === DOCUMENTS_MOMENT_WHEN_AFTER_FINALIZE) {
            this.documentErrors = [];
            let requiredDocumentsExist = [];

            this.activity.documents.models.forEach((document) => {
                if (this.activity.requiredDocuments.includes(document.type)){
                    requiredDocumentsExist.push(document.type);
                }
            });

            if (uniq(requiredDocumentsExist).length !== this.activity.requiredDocuments.length) {
                this.documentErrors.push(`${t('activity.noAttachDocs')}\n`);
            }

            if (this.activity.allocation.contract.attachDocuments.length === 0) {
                this.documentErrors.push(`${t('activity.noAttachDocOnContract')}\n`);
            }

            return this.activity.allocation.contract.documentsMomentWhen === DOCUMENTS_MOMENT_WHEN_AFTER_FINALIZE && !isEmpty(requiredDocumentsExist);
        }

        return true;
    }

    handleSave = (event) => {
        if (!this.waitingCostsConfirmedByUser) {
            event.preventDefault();
            this.showConfirmWaitingCostsModal = true;
        } else {
            this.saveFinalizing();
        }
    }

    render() {
        const activity = this.activity;
        const showSendDocumentsEmail = activity.type === 'unload' && activity.allocation.contract.documentsMomentWhen === 'after finalize' && this.props.showDocumentsEmail;

        if (this.isLoading) {
            return (
                <Dimmer active inverted>
                    <Loader inverted />
                </Dimmer>
            );
        }

        return (
            <Form data-test-activity-finalize={activity.id}>
                <Confirm
                    centered={false}
                    open={this.showConfirmWaitingCostsModal}
                    onCancel={() => {
                        this.showConfirmWaitingCostsModal = false;
                        this.savePermit = false;
                    }}
                    onConfirm={() => {
                        this.saveFinalizing();
                    }}
                    content={t('activity.waitingCostConfrim', { approved : this.activity.preliminaryWaitingAmount })}
                />

                <Details vertical>
                    <DetailsRow>
                        <Col xs={!this.props.showMap ? 12 : 8}>
                            {this.renderBasicInfo()}
                            {this.renderTrucks()}
                            {this.renderStops()}
                            {this.renderTimes()}
                            {this.renderInvoicing()}
                            {this.renderOtherCosts()}
                            <Row>
                                <Col xs>
                                    {this.renderRemarks()}
                                    {this.renderTrackedEquipment()}
                                </Col>
                                <Col xs>
                                    {this.renderDocuments()}
                                </Col>
                            </Row>
                        </Col>
                        {this.props.showMap && (
                            <DetailsCol flush xs={4}>
                                {showSendDocumentsEmail
                                    ? (
                                        <Fragment>
                                            <HalfRelativeMapContainer>
                                                {this.mouseOverTruckPosition && <TopRight>{this.mouseOverTruckPosition.odometer}</TopRight>}
                                                <ActivityTruckMap
                                                    store={this.truckPositionStore}
                                                    driverPoiStore={this.driverPoiStore}
                                                    activityRoute={this.activityRoute}
                                                    activityMarkers={this.activityMarkers}
                                                    onMouseOverTruckPosition={truckPosition => this.mouseOverTruckPosition = truckPosition}
                                                    onClickTruckPosition={truckPosition => {
                                                        const truck = new Truck({ truck: truckPosition.truck });
                                                        this.showModalChangeEndKm(activity, truck, truckPosition);
                                                    }}
                                                />
                                            </HalfRelativeMapContainer>
                                            <Col>
                                                <StyledComposeEmail wysiwyg
                                                    viewStore={this.props.viewStore}
                                                    model={this.email}
                                                    contactStore={this.contactStore}
                                                    requiredContactStore={this.contactStore}
                                                    errorMessages={this.documentErrors}
                                                />
                                            </Col>
                                        </Fragment>
                                    ) : (
                                        <RelativeMapContainer>
                                            {this.mouseOverTruckPosition && <TopRight>{this.mouseOverTruckPosition.odometer}</TopRight>}
                                            <ActivityTruckMap
                                                store={this.truckPositionStore}
                                                driverPoiStore={this.driverPoiStore}
                                                activityRoute={this.activityRoute}
                                                activityMarkers={this.activityMarkers}
                                                onMouseOverTruckPosition={truckPosition => this.mouseOverTruckPosition = truckPosition}
                                                onClickTruckPosition={truckPosition => {
                                                    const truck = new Truck({ truck: truckPosition.truck });
                                                    this.showModalChangeEndKm(activity, truck, truckPosition);
                                                }}
                                            />
                                        </RelativeMapContainer>
                                    )
                                }
                            </DetailsCol>
                        )}
                    </DetailsRow>
                    <DetailsRow>
                        <Col xs={showSendDocumentsEmail ? true : 8}>
                            <ButtonWrapper>
                                {!this.props.hideSkipButton && (
                                    <CancelButton onClick={this.handleCancel}>
                                        {t('activity.skipFinalizeButton')}
                                    </CancelButton>
                                )}
                                {
                                    !this.props.hideUnfinalizeButton &&
                                    this.activity.finalizedAt && (
                                    this.hasPermission('activity.unfinalize_activity:all') && (
                                        <ConfirmModal
                                            header={t('form.unfinalizeConfirmationTitle')}
                                            content={t('form.unfinalizeConfirmation', { id: activity.id })}
                                            onApply={() => activity.unFinalize().then(() => {
                                                this.props.viewStore.showSaveNotification();
                                            })}
                                            trigger={props => (
                                                <Button data-test-unfinalize
                                                    loading={this.activity.isLoading}
                                                    disabled={this.activity.invoiceLineItems && this.activity.invoiceLineItems.length > 0}
                                                    title={this.activity.invoiced ? t('activity.invoicedActivity') : ''}
                                                    {...props}
                                                >
                                                    {t('form.unfinalizeButton')}
                                                </Button>
                                            )}
                                        />
                                    )
                                )}
                                <RightDivider />
                                <BottomDivider />
                                {!this.props.hideRegenerateTemperaturePrintButton && activity.conditions.includes(CONDITION_TEMPERATURE) && (
                                    <AncientRefreshButton
                                        loading={this.activity.isLoading}
                                        onClick={() => this.activity.regenerateTemperaturePrint().then(showSaveNotification)}
                                    >
                                        {t('activity.regenerateTemperaturePrintButtonTitle')}
                                    </AncientRefreshButton>
                                )}
                                {!this.props.hideSaveButton && (
                                    <SaveButton data-test-save
                                        onClick={this.save}
                                        title={!this.activity.canFinalize ? t('activity.missingPlannedKmGivenKm') : ''}
                                        disabled={!this.activity.canFinalize || this.activity.isLoading}
                                        loading={this.activity.isLoading}
                                    />
                                )}
                                {this.renderFinalizeButton()}
                            </ButtonWrapper>
                        </Col>
                    </DetailsRow>
                </Details>
            </Form>
        );
    }

    getActivityTypeValue = (activity) => {
        if (activity.type === TYPE_TRAILER_CHANGE) {
            let label = "";
            let licensePlate = "";

            if (activity.nextTrailer.isNew) {
                label = t('trailer.dropLabel').toLowerCase();
                licensePlate = activity.trailer?.licensePlate || 'N/A';
            } else {
                label = t('trailer.pickUpLabel').toLowerCase();
                licensePlate = activity.nextTrailer?.licensePlate || 'N/A';
            }

            return `${TYPE_TRAILER_CHANGE} (${label} ${licensePlate})`.replace(/\s+/g, ' ').trim();
        }

        if (activity.type === TYPE_CONTAINER_CHANGE) {
            let label1 = "";
            let label2 = "";
            let licensePlate1 = "";
            let licensePlate2 = "";

            if (activity.nextContainer1?.isNew) {
                label1 = t('container.dropLabel').toLowerCase();
                licensePlate1 = activity.container1?.licensePlate || 'N/A';
            } else {
                label1 = t('container.pickUpLabel').toLowerCase();
                licensePlate1 = activity.nextContainer1?.licensePlate || 'N/A';
            }

            if (activity.nextContainer2?.isNew) {
                label2 = t('container.dropLabel').toLowerCase();
                licensePlate2 = activity.container2?.licensePlate || 'N/A';
            } else {
                label2 = t('container.pickUpLabel').toLowerCase();
                licensePlate2 = activity.nextContainer2?.licensePlate || 'N/A';
            }

            return `${TYPE_CONTAINER_CHANGE} (${label1} ${licensePlate1} / ${label2} ${licensePlate2})`
                .replace(/\s+/g, ' ')
                .trim();
        }

        return activity.type;
    };

    renderBasicInfo = () => {
        const activity = this.activity;
        const prevActivity = activity.previousActivity;

        return (
            <React.Fragment>
                <Subheading>{t('planning.customer.panelTitles.finalizeActivity')}-{activity.id} ({activity.trailer.licensePlate || 'No trailer found'})</Subheading>
                <Row>
                    <Col xs>
                        <FormTextInput
                            model={activity}
                            disabled={activity.invoiced}
                            name="orderReference"
                        />
                    </Col>
                    <Col xs>
                        <FormField label={t('planning.activityFinalize.currentActivityLabel')}>
                            <TextInput
                                name="currentActivityCity"
                                value={activity.location.city || '-'}
                                disabled
                            />
                        </FormField>
                    </Col>
                    <Col xs>
                        <FormField
                            label={t('planning.activityFinalize.activityTypeLabel')}>
                                {/* <div onClick={()=>console.log(activity.toJS())}>test</div> */}
                            <TextInput disabled
                                name="currentActivityType"
                                // pickup-dropoff-drama
                                value={this.getActivityTypeValue(activity)}
                            />
                        </FormField>
                    </Col>
                </Row>
                <Row>
                    <Col xs>
                        <FormField label={t('planning.activityFinalize.dateLabel')}>
                            <TextInput
                                name="previousActivityDate"
                                value={!prevActivity.isNew ? formatDatetime(prevActivity.orderedArrivalDatetimeFrom) : '-'}
                                disabled
                            />
                        </FormField>
                    </Col>
                    <Col xs>
                        <FormField label={t('planning.activityFinalize.previousActivityLabel')}>
                            <TextInput
                                name="previousActivityCity"
                                value={!prevActivity.isNew ? prevActivity.location.city || '-' : '-'}
                                disabled
                            />
                        </FormField>
                    </Col>
                    <Col xs>
                        <FormField label={t('planning.activityFinalize.activityTypeLabel')}>
                            <TextInput
                                name="previousActivityType"
                                value={!prevActivity.isNew ? prevActivity.type || '-' : '-'}
                                disabled
                            />
                        </FormField>
                    </Col>
                </Row>
            </React.Fragment>
        );
    };

    renderTrucks = () => {
        const activity = this.activity;

        return (
            <React.Fragment>
                <SuperText>
                    {t('planning.customer.panelTitles.trucks')}
                </SuperText>
                <ActivityFinalizeTruck
                    consecutiveTrucks={activity.getConsecutivelyDrivenByTruck()}
                    onClickEndKm={consecutiveTruck => this.showModalChangeEndKm(activity, consecutiveTruck.truck)}
                />
                <Row>
                    <Col xs>
                        <FormTextInput
                            disabled
                            model={activity}
                            name="drivenKm"
                            value={activity.drivenKmIncludingStops}
                        />
                    </Col>
                    <Col xs>
                        <FormTextInput
                            disabled
                            model={activity}
                            name="deviationKm"
                            value={activity.deviationKmIncludingStops}
                        />
                    </Col>
                    <Col xs>
                        <FormField label={t('activity.field.givenKm.label')}>
                            <FormNumberInput
                                label={null}
                                disabled={this.disableGivenKm()}
                                model={activity}
                                name="givenKm"
                                value={activity.givenKm || ''}
                                onChange={this.handleChangeKm}
                            />
                        </FormField>
                    </Col>
                </Row>
                <Row>
                    <Col xs>
                        <FormField label={t('activity.field.plannedKm.label')}>
                            <FormNumberInput
                                label={null}
                                disabled={this.disablePlannedKm()}
                                name="plannedKm"
                                model={activity}
                                onChange={this.handleChangeKm}
                            />
                        </FormField>
                    </Col>
                    <Col xs>
                        <FormTextInput
                            model={activity}
                            name="deviationKmPercent"
                            value={activity.deviationKmPercentIncludingStops}
                            disabled
                        />
                    </Col>
                    <Col xs>
                        <FormField label={t('activity.field.unpaidKm.label')}>
                            <TextInput
                                name="unpaidKm"
                                value={activity.givenKm ? activity.unpaidKm : ''}
                                disabled
                            />
                        </FormField>
                    </Col>
                </Row>
                <Row>
                    <Col md>
                        <FormField label={t('activity.field.deviationKm.label')}>
                            <TextArea
                                name="kmDeviation"
                                value={activity.kmDeviation}
                                onChange={this.handleChange}
                            />
                        </FormField>
                    </Col>
                </Row>
            </React.Fragment>
        );
    };

    renderTrackedEquipment() {
        // enable editing only if current unfinalized activity is the most recent finished
        const isLastFinished = !!this.lastFinishedActivityStore.get(this.activity.id)
        const isFinalized = !!this.activity.finalizedAt

        return this.activity.allocation.contract.trackedEquipments.length > 0 && (
            <React.Fragment>
                <TrackedEquipmentTable
                    disabled={!isLastFinished || isFinalized}
                    trackingTarget={TRACKING_TARGET_TRAILER}
                    allocation={this.activity.allocation}
                    activity={this.activity}
                    previousActivityStore={this.previousActivityStore}
                />
                <TrackedEquipmentTable
                    disabled={!isLastFinished || isFinalized}
                    trackingTarget={TRACKING_TARGET_ALLOCATION}
                    allocation={this.activity.allocation}
                    activity={this.activity}
                    previousActivityStore={this.previousActivityStore}
                />
            </React.Fragment>
        );
    }

    renderStops = () => {
        const activity = this.activity;

        return (
            <React.Fragment>
                <SuperText>{t('planning.customer.panelTitles.stops')}</SuperText>
                <ActivityFinalizeStops activities={activity.previousStops} />
            </React.Fragment>
        );
    };

    renderTimes = () => {
        const activity = this.activity;
        const currencySymbol = getCurrencySymbolFromCode(activity.allocation.contract.fcCode)

        return (
            <React.Fragment>
                <SuperText>{t('planning.customer.panelTitles.times')}</SuperText>
                <Row>
                    <Col xs={3}>
                        <FormField label={t('activity.field.orderedArrivalDatetimeFrom.label')}>
                            <TextInput
                                name="currentPlannedArrivalDatetime"
                                value={formatDatetime(activity.orderedArrivalDatetimeFrom)}
                                disabled
                            />
                        </FormField>
                    </Col>
                    <Col xs={3}>
                        <FormDatetimePicker
                            model={activity}
                            name="actualArrivalDatetime"
                            fromModel={datetime => moment.tz(datetime, activity.location.timezone)}
                            disabled={!!activity.finalizedAt}
                        />
                    </Col>
                    <Col xs={3}>
                        <FormField label={t('activity.field.waitTimeActual.label')}>
                            <TextInput
                                name="waitTimeActual"
                                value={(() => {
                                    if (!activity.actualArrivalDatetime || !activity.finishedDatetime) {
                                        return '-';
                                    }

                                    const diff = moment.duration(
                                        activity.finishedDatetime.diff(
                                            activity.actualArrivalDatetime
                                        )
                                    );
                                    return diff.format('hh:mm', { trim: false });
                                })()}
                                disabled
                            />
                        </FormField>
                    </Col>
                    <Col xs={3}>
                        <FormField
                            label={t('activity.field.equipmentPlanned.label')}
                            error={activity.backendValidationErrors.equipmentPlanned}
                        >
                            <TextInput
                                disabled={!!activity.finalizedAt}
                                name="equipmentPlanned"
                                value={activity.equipmentPlanned}
                                onChange={this.handleChange}
                            />
                        </FormField>
                    </Col>
                </Row>
                <Row>
                    <Col xs={3}>
                        <FormField label={t('activity.field.orderedArrivalDatetimeUntil.label')}>
                            <TextInput
                                disabled
                                name="currentPlannedDepartureDatetime"
                                value={formatDatetime(activity.orderedArrivalDatetimeUntil)}
                            />
                        </FormField>
                    </Col>
                    <Col xs={3}>
                        <FormDatetimePicker
                            model={activity}
                            name="startDatetime"
                            fromModel={datetime => moment.tz(datetime, activity.location.timezone)}
                            disabled={!!activity.finalizedAt}
                        />
                    </Col>
                    <Col xs={3} title={activity._tariffWaitingRateExplaination}>
                        <FormField
                            label={t('activity.field.calculatedWaitingAmount.label')}
                            error={activity.backendValidationErrors.calculatedWaitingAmount}
                        >
                            <NumberInput
                                name="calculatedWaitingAmount"
                                value={getMoneyForUser(activity.calculatedWaitingAmount)}
                                onChange={this.handleChange}
                                {...getMoneyInputProps()}
                                prefix={currencySymbol}
                                disabled
                            />
                        </FormField>
                    </Col>
                    <Col xs={3}>
                        <FormField
                            label={t('activity.field.equipmentActual.label')}
                            error={activity.backendValidationErrors.equipmentActual}
                        >
                            <TextInput
                                disabled={!!activity.finalizedAt}
                                name="equipmentActual"
                                value={activity.equipmentActual}
                                onChange={this.handleChange}
                            />
                        </FormField>
                    </Col>
                </Row>
                <Row>
                    <Col xs={3}>
                        <FormField
                            label={t('activity.field.expectedArrivalDatetime.label')}
                        >
                            <TextInput
                                name="currentExpectedArrivalDatetime"
                                value="-"
                                disabled
                            />
                        </FormField>
                    </Col>
                    <Col xs={3}>
                        <FormDatetimePicker
                            model={activity}
                            name="finishedDatetime"
                            fromModel={datetime => moment.tz(datetime, activity.location.timezone)}
                            disabled={!!activity.finalizedAt}
                            onChange={this.handleChange}
                        />
                    </Col>
                    <Col xs={3}>
                        <FormField
                            label={t('activity.field.preliminaryWaitingAmount.label')}
                            error={activity.backendValidationErrors.preliminaryWaitingAmount}
                        >
                            <NumberInput data-test-expected-waiting-cost
                                disabled={!!activity.finalizedAt}
                                name="preliminaryWaitingAmount"
                                value={getMoneyForUser(activity.preliminaryWaitingAmount)}
                                onChange={this.handleChange}
                                {...getMoneyInputProps()}
                                prefix={currencySymbol}
                            />
                        </FormField>
                    </Col>
                    <Col xs={3}>
                        <FormField
                            label={t('activity.field.tollCosts.label')}
                            error={activity.backendValidationErrors.tollCosts}
                        >
                            <NumberInput
                                name="tollCosts"
                                value={getMoneyForUser(activity.tollCosts)}
                                onChange={this.handleChange}
                                {...getMoneyInputProps()}
                                disabled
                            />
                        </FormField>
                    </Col>
                </Row>
            </React.Fragment>
        );
    };

    renderInvoicing = () => {
        const activity = this.activity;

        if (!(this.props.showCustomFinalizedModule && this.userCanViewInvoices)) {
            return null;
        }

        return (
            <React.Fragment>
                <SuperText>
                    {t('planning.customer.panelTitles.invoicing')}
                    {' '}
                    <ActivityInvoiceStatus activity={activity} />
                </SuperText>

                <Table data-test-invoicing>
                    <ActivityHeader filler showCustomAmount
                        showColumnVat={false}
                        showActivityColumns={false}
                    />
                    <TBody>
                        <CustomActivityTotals showColumnCustom
                            title="Expected"
                            showColumnVat={false}
                            fillerColspan={false}
                            invoicedKm={activity.expectedKm}
                            kmAmount={activity.expectedKmAmount}
                            fixedAmount={activity.expectedFixedAmount}
                            waitingAmount={activity.expectedWaitingAmount}
                            secondDriverAmount={activity.expectedSecondDriverAmount}
                            tollAmount={activity.expectedTollAmount}
                            kmSurcharge={activity.expectedKmSurcharge}
                            fixedSurcharge={activity.expectedFixedSurcharge}
                            otherCosts={activity.expectedOtherCostsAmount}
                            customAmount={activity.invoicedCustomAmount}
                            total={activity.expectedAmount}
                            currencySymbol={this._currencySymbol}
                        />
                        <CustomActivityTotals showColumnCustom
                            title="Waived"
                            color={COLOR_INVOICE_WAIVED}
                            showColumnVat={false}
                            fillerColspan={false}
                            invoicedKm={activity.waivedInvoicedKm}
                            kmAmount={activity.waivedKmAmount}
                            fixedAmount={activity.waivedFixedAmount}
                            waitingAmount={activity.waivedWaitingAmount}
                            secondDriverAmount={activity.waivedSecondDriverAmount}
                            tollAmount={activity.waivedTollAmount}
                            kmSurcharge={activity.waivedKmSurcharge}
                            fixedSurcharge={activity.waivedFixedSurcharge}
                            otherCosts={activity.waivedOtherCostsAmount}
                            customAmount={activity.waivedCustomAmount}
                            total={activity.waivedAmount}
                            currencySymbol={this._currencySymbol}
                        />
                        <CustomActivityTotals showColumnCustom
                            title={t('invoice.corrected')}
                            color={COLOR_INVOICE_CORRECTED}
                            showColumnVat={false}
                            fillerColspan={false}
                            invoicedKm={activity.correctedInvoicedKm}
                            kmAmount={activity.correctedKmAmount}
                            fixedAmount={activity.correctedFixedAmount}
                            waitingAmount={activity.correctedWaitingAmount}
                            secondDriverAmount={activity.correctedSecondDriverAmount}
                            tollAmount={activity.correctedTollAmount}
                            kmSurcharge={activity.correctedKmSurcharge}
                            fixedSurcharge={activity.correctedFixedSurcharge}
                            otherCosts={activity.correctedOtherCostsAmount}
                            customAmount={activity.correctedCustomAmount}
                            total={activity.correctedAmount}
                            currencySymbol={this._currencySymbol}
                        />
                        <CustomActivityTotals showColumnCustom
                            title={t('invoice.displayType.invoiced')}
                            color={COLOR_INVOICE_INVOICED}
                            sign="-"
                            borders="bottom"
                            showColumnVat={false}
                            fillerColspan={false}
                            invoicedKm={activity.invoicedInvoicedKm}
                            kmAmount={activity.invoicedKmAmount}
                            fixedAmount={activity.invoicedFixedAmount}
                            waitingAmount={activity.invoicedWaitingAmount}
                            secondDriverAmount={activity.invoicedSecondDriverAmount}
                            tollAmount={activity.invoicedTollAmount}
                            kmSurcharge={activity.invoicedKmSurcharge}
                            fixedSurcharge={activity.invoicedFixedSurcharge}
                            otherCosts={activity.invoicedOtherCostsAmount}
                            customAmount={activity.invoicedCustomAmount}
                            total={activity.invoicedAmount}
                            currencySymbol={this._currencySymbol}
                        />
                        <CustomActivityTotals showColumnCustom
                            title="Disputed"
                            color={COLOR_INVOICE_DISPUTED}
                            showColumnVat={false}
                            fillerColspan={false}
                            invoicedKm={activity.expectedInvoicedKm - activity.waivedInvoicedKm - activity.correctedInvoicedKm - activity.invoicedInvoicedKm}
                            kmAmount={activity.expectedKmAmount - activity.waivedKmAmount - activity.correctedKmAmount - activity.invoicedKmAmount}
                            fixedAmount={activity.expectedFixedAmount - activity.waivedFixedAmount - activity.correctedFixedAmount - activity.invoicedFixedAmount}
                            waitingAmount={activity.expectedWaitingAmount - activity.waivedWaitingAmount - activity.correctedWaitingAmount - activity.invoicedWaitingAmount}
                            secondDriverAmount={activity.expectedSecondDriverAmount - activity.waivedSecondDriverAmount - activity.correctedSecondDriverAmount - activity.invoicedSecondDriverAmount}
                            tollAmount={activity.expectedTollAmount - activity.waivedTollAmount - activity.correctedTollAmount - activity.invoicedTollAmount}
                            kmSurcharge={activity.expectedKmSurcharge - activity.waivedKmSurcharge - activity.correctedKmSurcharge - activity.invoicedKmSurcharge}
                            fixedSurcharge={activity.expectedFixedSurcharge - activity.waivedFixedSurcharge - activity.correctedFixedSurcharge - activity.invoicedFixedSurcharge}
                            otherCosts={activity.expectedOtherCostsAmount - activity.waivedOtherCostsAmount - activity.correctedOtherCostsAmount - activity.invoicedOtherCostsAmount}
                            customAmount={activity.expectedCustomAmount - activity.waivedCustomAmount - activity.correctedCustomAmount - activity.invoicedCustomAmount}
                            currencySymbol={this._currencySymbol}
                            total={(
                                <UnstyledA
                                    href={`/administration/dispute/overview?${urlEncodeObject({
                                        '.id:in': activity.invoiceLineItems.models.filter(x=>x.invoiceLine.dispute).reduce((res, item) => [...res, item.invoiceLine.dispute.id], []).join(','),
                                    })}`}
                                    target="_blank"
                                >
                                    {this._currencySymbol}{getMoneyForUser(activity.expectedAmount - activity.waivedAmount - activity.correctedAmount - activity.invoicedAmount)}
                                </UnstyledA>
                            )}
                        />
                        <CustomActivityTotals showColumnCustom data-test-totals-registered-costs
                            title="Registered Costs"
                            color={COLOR_INVOICE_COSTS}
                            showColumnVat={false}
                            fillerColspan={false}
                            invoicedKm=""
                            kmAmount=""
                            fixedAmount=""
                            waitingAmount=""
                            secondDriverAmount=""
                            tollAmount=""
                            kmSurcharge=""
                            fixedSurcharge=""
                            otherCosts=""
                            customAmount=""
                            total={activity.costItems.models.reduce((res, ci) => res + ci.amount, 0)}
                        />
                    </TBody>
                </Table>
                {this.props.showCustomFinalizedModule && this.userCanViewInvoices && (
                    <React.Fragment>
                        <SuperText>{t('planning.customer.panelTitles.invoices')}</SuperText>
                        <ActivityFinalizeInvoices activity={activity} />
                        <SuperText>{t('planning.customer.panelTitles.waivers')}</SuperText>
                        <ActivityFinalizeWaivers activity={activity} />
                        <SuperText>{t('planning.customer.panelTitles.costs')}</SuperText>
                        <ActivityFinalizeCosts activity={activity} />
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    };

    @action
    detachDoc = (doc) => {
        this.activity.documents.remove(doc);

        const newActivity = new Activity();
        doc.setInput('status', 'pending');
        doc.setInput('activity', newActivity);
        doc.save({ onlyChanges: true }).then(() => {
            this.props.viewStore.showSaveNotification();
        });
    };

    renderRemarks = () => {
        const activity = this.activity

        return (
            <React.Fragment>
                <UppercaseHeaderRight content={t('activity.field.remarks.label')}>
                    <BEPContextButtons
                        currentUser={this.props.viewStore.currentUser ? this.props.viewStore.currentUser : {}}
                        entity={activity.allocation.contract.entity}
                        contextType={CONTEXT_TYPE_ACTIVITY}
                        contextActivity={activity}
                    />
                </UppercaseHeaderRight>
                <FormField error={activity.backendValidationErrors.remarks}>
                    <TextArea
                        name="remarks"
                        value={activity.remarks}
                        onChange={this.handleChange}
                    />
                </FormField>
                <UppercaseHeaderRight content={t('activity.field.instructions.label')}/>
                <FormField error={activity.backendValidationErrors.instructions}>
                    <TextArea disabled
                        name="instructions"
                        value={activity.instructions}
                        onChange={this.handleChange}
                    />
                </FormField>
        </React.Fragment>
        )
    }

    renderDocuments = () => {
        const activity = this.activity

        return (
            <React.Fragment>
                <HeaderRight content={t('activity.field.requiredDocuments.label')} store={activity.requiredDocuments} />
                <TableS celled compact definition>
                    <TableS.Header fullWidth>
                        <TableS.Row>
                            <TableS.HeaderCell></TableS.HeaderCell>
                            <TableS.HeaderCell>{t('document.field.type.label')}</TableS.HeaderCell>
                            <TableS.HeaderCell />
                        </TableS.Row>
                    </TableS.Header>
                    <TableS.Body>
                        {activity.requiredDocuments.map(docType => (
                            <ActivityFinalizeDocument
                                viewStore={this.props.viewStore}
                                key={docType}
                                activity={activity}
                                type={docType}
                                onDocumentApprove={this.onDocumentApprove}
                            />
                        ))}
                    </TableS.Body>
                </TableS>
                <HeaderRight content={t('activity.field.documents.label')} store={activity.documents}>
                    <IconButton data-test-regenerate-temperature-print-button
                        title={t('activity.regenerateTemperaturePrintButtonTitle')}
                        size="large"
                        name="thermometer half"
                        loading={this.activity.isLoading}
                        onClick={() => this.activity.regenerateTemperaturePrint().then(showSaveNotification)}
                    />
                </HeaderRight>
                <TableS celled compact definition data-test-attached-documents>
                    <TableS.Header fullWidth>
                        <TableS.Row>
                            <TableS.HeaderCell>{t('document.field.id.label')}</TableS.HeaderCell>
                            <TableS.HeaderCell>{t('document.field.type.label')}</TableS.HeaderCell>
                            <TableS.HeaderCell />
                        </TableS.Row>
                    </TableS.Header>
                    <TableS.Body>
                        {activity.documents.filter(item=>(item.status!=='rejected' && item.status!=='rejected by customer' && item.status!=='scan requested')).map(doc => {
                            if (doc.deleted) {
                                return null;
                            }

                            return (
                                <TableS.Row key={doc.id} data-test-attached-document={doc.id}>
                                    <TableS.Cell collapsing>
                                        {doc.id}
                                    </TableS.Cell>
                                    <TableS.Cell>
                                        {t(`document.field.type.options.${doc.type}`)}
                                    </TableS.Cell>
                                    <TableS.Cell collapsing>
                                        <IconButton name="delete" onClick={() => this.detachDoc(doc)}/>
                                        <IconButton data-test-download-button download
                                            name="download"
                                            href={doc.pdfFile || doc.file}
                                        />
                                    </TableS.Cell>
                                </TableS.Row>
                            );
                        })}
                    </TableS.Body>
                </TableS>
                <OrderConfirmationCards orderConfirmationStore={this.activity.orderConfirmations} />
            </React.Fragment>
        )
    }

    renderOtherCosts = () => {
        return (
            <Row data-test-other-costs>
                <Col xs={6}>
                    <ActivityFinalizeOtherCosts
                        activity={this.activity}
                    />
                </Col>
                {this.props.showCustomFinalizedModule && (
                    <Col xs={6}>
                        <FormRadioButtons
                            model={this.activity}
                            name="outsideEu"
                            options={[{
                                label: t('activity.field.outsideEu.options.no'),
                                value: false,
                            }, {
                                label: t('activity.field.outsideEu.options.yes'),
                                value: true,
                            }]}
                        />
                    </Col>
                )}
            </Row>
        );
    };

    renderFinalizeButton() {
        if (!!this.activity.finalizedAt) {
            return null;
        }

        return (
            <SaveButton data-test-save-and-finalize
                onClick={this.handleSave}
                loading={this.activity.isLoading}
                disabled={!this.activity.canFinalize || this.documentErrors.length > 0 || this.activity.isLoading}
            >
                {t('activity.saveFinalizeButton')}
            </SaveButton>
        );
    }
}
