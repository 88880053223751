export default `
{{customerId}} / {{trailerPlate}} ETA to Container Change {{currentLocation}}



{{customerId}} / {{trailerPlate}} his ETA at Container Change {{currentLocation}} is: {{currentCommunicatedTime}}
Reason:

{{timezoneOffsetHoursWithCest}}

Best Regards,
{{userFullName}}
Boekestijn Transport Service
{{userEmailSignature}}
`;
