import requestNewPlan from './request-new-plan';
import loadArrived from './load-arrived';
import loadEtaOther from './eta/load-eta-other';
import loadEtaTachoStop from './eta/load-eta-tachoStop';
import loadEtaTrafficJam from './eta/load-eta-trafficJam';
import loadEtaPoliceControl from './eta/load-eta-policeControl';
import trailerChangeEtaOther from './eta/trailer-change-eta-other';
import trailerChangeEtaPoliceControl from './eta/trailer-change-eta-policeControl';
import trailerChangeEtaTachoStop from './eta/trailer-change-eta-tachoStop';
import trailerChangeEtaTrafficJam from './eta/trailer-change-eta-trafficJam';
import loadFinished from './load-finished';
import loadFinishedNonext from './load-finished-nonext';
import loadFinishedNextboek from './load-finished-nextboek';
import loadStarted from './load-started';
import loadWaitingOther from './waiting/load-waiting-other';
import loadWaitingNotReady from './waiting/load-waiting-notReady';
import loadWaitingWrongReference from './waiting/load-waiting-wrongReference';
import loadWaitingLine from './waiting/load-waiting-line';
import loadWaitingCompanyClose from './waiting/load-waiting-companyClosed';
import unloadArrived from './unload-arrived';
import unloadEtaOther from './eta/unload-eta-other';
import unloadEtaPoliceControl from './eta/unload-eta-policeControl';
import unloadEtaTachoStop from './eta/unload-eta-tachoStop';
import unloadEtaTrafficJam from './eta/unload-eta-trafficJam';
import unloadFinished from './unload-finished';
import unloadFinishedNonext from './unload-finished-nonext';
import unloadFinishedNextboek from './unload-finished-nextboek';
import unloadStarted from './unload-started';
import unloadWaitingOther from './waiting/unload-waiting-other';
import unloadWaitingWrongReference from './waiting/unload-waiting-wrongReference';
import unloadWaitingCompanyClosed from './waiting/unload-waiting-companyClosed';
import unloadWaitingLine from './waiting/unload-waiting-line';
import unloadWaitingNotReady from './waiting/unload-waiting-notReady';
import trailerChangeWaitingWrongReference from './waiting/trailer-change-waiting-wrongReference';
import trailerChangeWaitingNotReady from './waiting/trailer-change-waiting-notReady';
import trailerChangeWaitingCompanyClosed from './waiting/trailer-change-waiting-companyClosed';
import trailerChangeWaitingLine from './waiting/trailer-change-waiting-line';
import trailerChangeWaitingOther from './waiting/trailer-change-waiting-other';
import trailerChangeArrived from './trailer-change-arrived';
import trailerChangeEta from './trailer-change-eta';
import trailerChangeFinished from './trailer-change-finished';
import trailerChangeFinishedNonext from './trailer-change-finished-nonext';
import trailerChangeFinishedNextboek from './trailer-change-finished-nextboek';
import trailerChangeStarted from './trailer-change-started';
import trailerChangeWaiting from './trailer-change-waiting';
import poiArrived from './poi-arrived';
import poiEta from './poi-eta';
import poiFinished from './poi-finished';
import poiFinishedNextboek from './poi-finished-nextboek';
import poiFinishedNonext from './poi-finished-nonext';
import poiStarted from './poi-started';
import poiWaitingCompanyClosed from './waiting/poi-waiting-companyClosed';
import poiWaitingLine from './waiting/poi-waiting-line';
import poiWaitingNotReady from './waiting/poi-waiting-notReady';
import poiWaitingOther from './waiting/poi-waiting-other';
import poiWaitingWrongReference from './waiting/poi-waiting-wrongReference';
import poiEtaOther from './eta/poi-eta-other';
import poiEtaPoliceControl from './eta/poi-eta-policeControl';
import poiEtaTachoStop from './eta/poi-eta-tachoStop';
import poiEtaTrafficJam from './eta/poi-eta-trafficJam';

import containerChangeArrived from './container-change-arrived';
import containerChangeEta from './container-change-eta';
import containerChangeFinished from './container-change-finished';
import containerChangeFinishedNextboek from './container-change-finished-nextboek';
import containerChangeFinishedNonext from './container-change-finished-nonext';
import containerChangeStarted from './container-change-started';
import containerChangeWaitingCompanyClosed from './waiting/container-change-waiting-companyClosed';
import containerChangeWaitingLine from './waiting/container-change-waiting-line';
import containerChangeWaitingNotReady from './waiting/container-change-waiting-notReady';
import containerChangeWaitingOther from './waiting/container-change-waiting-other';
import containerChangeWaitingWrongReference from './waiting/container-change-waiting-wrongReference';
import containerChangeEtaOther from './eta/container-change-eta-other';
import containerChangeEtaPoliceControl from './eta/container-change-eta-policeControl';
import containerChangeEtaTachoStop from './eta/container-change-eta-tachoStop';
import containerChangeEtaTrafficJam from './eta/container-change-eta-trafficJam';


// Customers Stops ETA
import customerStopTrafficJam from './customerStop/eta/common/customerStop-trafficJam';
import customerStopPliceControl from './customerStop/eta/common/customerStop-policeControl';
import customerStopTachoStop from './customerStop/eta/common/customerStop-tachoStop';

import ferryTunnel from './customerStop/eta/customerStop-ferryTunnel-eta-other';
import ferryTunnelArrived from './customerStop/eta/customerStop-ferryTunnel-arrived';
import ferryTunnelStarted from './customerStop/eta/customerStop-ferryTunnel-eta-started';
import ferryTunnelFinished from './customerStop/eta/customerStop-ferryTunnel-eta-finished';

import secureParking from './customerStop/eta/customerStop-secureParking-eta-other';
import secureParkingStarted from './customerStop/eta/customerStop-secureParking-started';
import secureParkingFinished from './customerStop/eta/customerStop-secureParking-finished';

import parking from './customerStop/eta/customerStop-secureParking-eta-other';
import parkingStarted from './customerStop/eta/customerStop-secureParking-started';
import parkingFinished from './customerStop/eta/customerStop-secureParking-finished';

import equipmentPickUp from './customerStop/eta/customerStop-equipmentPickUp-eta-other';
import equipmentPickUpStarted from './customerStop/eta/customerStop-equipmentPickUp-started';
import equipmentPickUpFinished from './customerStop/eta/customerStop-equipmentPickUp-finished';

import homebase from './customerStop/eta/customerStop-homebase-eta-other';
import homebaseStarted from './customerStop/eta/customerStop-homebase-started';
import homebaseFinished from './customerStop/eta/customerStop-homebase-finished';

import service from './customerStop/eta/customerStop-service-eta-other';
import serviceStarted from './customerStop/eta/customerStop-service-started';
import serviceFinished from './customerStop/eta/customerStop-service-finished';

import borderCustoms from './customerStop/eta/customerStop-borderCustoms-eta-other';
import borderCustomsStarted from './customerStop/eta/customerStop-borderCustoms-started';
import borderCustomsFinished from './customerStop/eta/customerStop-borderCustoms-finished';

import other from './customerStop/eta/customerStop-other-eta-other';
import otherStarted from './customerStop/eta/customerStop-other-started';
import finished from './customerStop/eta/customerStop-other-finished';

// WAITING
import customerStopWrongReference from './customerStop/waiting/customerStop-wrongReference';
import customerStopCompanyClosed from './customerStop/waiting/customerStop-companyClosed';
import customerStopNotReady from './customerStop/waiting/customerStop-notReady';
import customerStopLine from './customerStop/waiting/customerStop-line';
import customerStopArrived from './customerStop/customerStop-borderCustoms-arrived';


import boekStopServiceEta from './boekStop/eta/boekStop-service-eta-other.js';
import boekStopServiceFinished from './boekStop/eta/boekStop-service-finished.js';
import boekStopServiceStarted from './boekStop/eta/boekStop-service-started.js';


export default {
    'request-new-plan': requestNewPlan,
    'load-arrived': loadArrived,
    'load-eta-other': loadEtaOther,
    'load-eta-tachoStop': loadEtaTachoStop,
    'load-eta-trafficJam': loadEtaTrafficJam,
    'load-eta-policeControl': loadEtaPoliceControl,
    'load-finished': loadFinished,
    'load-finished-nonext': loadFinishedNonext,
    'load-finished-nextboek': loadFinishedNextboek,
    'load-started': loadStarted,
    'load-waiting-other': loadWaitingOther,
    'load-waiting-notReady': loadWaitingNotReady,
    'load-waiting-wrongReference': loadWaitingWrongReference,
    'load-waiting-line': loadWaitingLine,
    'load-waiting-companyClosed': loadWaitingCompanyClose,
    'unload-arrived': unloadArrived,
    'unload-eta-other': unloadEtaOther,
    'unload-eta-policeControl': unloadEtaPoliceControl,
    'unload-eta-tachoStop': unloadEtaTachoStop,
    'unload-eta-trafficJam': unloadEtaTrafficJam,
    'unload-finished': unloadFinished,
    'unload-finished-nonext': unloadFinishedNonext,
    'unload-finished-nextboek': unloadFinishedNextboek,
    'unload-started': unloadStarted,
    'unload-waiting-other': unloadWaitingOther,
    'unload-waiting-notReady': unloadWaitingNotReady,
    'unload-waiting-wrongReference': unloadWaitingWrongReference,
    'unload-waiting-line': unloadWaitingLine,
    'unload-waiting-companyClosed': unloadWaitingCompanyClosed,
    'trailer-change-arrived': trailerChangeArrived,
    'trailer-change-eta': trailerChangeEta,
    'trailer-change-finished': trailerChangeFinished,
    'trailer-change-finished-nonext': trailerChangeFinishedNonext,
    'trailer-change-finished-nextboek': trailerChangeFinishedNextboek,
    'trailer-change-started': trailerChangeStarted,
    'trailer-change-waiting': trailerChangeWaiting,
    'trailer-change-eta-other': trailerChangeEtaOther,
    'trailer-change-eta-policeControl': trailerChangeEtaPoliceControl,
    'trailer-change-eta-tachoStop': trailerChangeEtaTachoStop,
    'trailer-change-eta-trafficJam': trailerChangeEtaTrafficJam,
    'trailer-change-waiting-wrongReference': trailerChangeWaitingWrongReference,
    'trailer-change-waiting-notReady': trailerChangeWaitingNotReady,
    'trailer-change-waiting-companyClosed': trailerChangeWaitingCompanyClosed,
    'trailer-change-waiting-line': trailerChangeWaitingLine,
    'trailer-change-waiting-other': trailerChangeWaitingOther,

    //poi
    'poi-arrived': poiArrived,
    'poi-finished-nextboek': poiFinishedNextboek,
    'poi-finished-nonext': poiFinishedNonext,
    'poi-finished': poiFinished,
    'poi-eta': poiEta,
    'poi-started': poiStarted,
    'poi-waiting-wrongReference': poiWaitingWrongReference,
    'poi-waiting-companyClosed': poiWaitingCompanyClosed,
    'poi-waiting-line': poiWaitingLine,
    'poi-waiting-notReady': poiWaitingNotReady,
    'poi-waiting-other': poiWaitingOther,
    'poi-eta-other': poiEtaOther,
    'poi-eta-policeControl': poiEtaPoliceControl,
    'poi-eta-tachoStop': poiEtaTachoStop,
    'poi-eta-trafficJam': poiEtaTrafficJam,


    //containerChange
    'container-change-arrived': containerChangeArrived,
    'container-change-finished-nextboek': containerChangeFinishedNextboek,
    'container-change-finished-nonext': containerChangeFinishedNonext,
    'container-change-finished': containerChangeFinished,
    'container-change-eta': containerChangeEta,
    'container-change-started': containerChangeStarted,
    'container-change-waiting-wrongReference': containerChangeWaitingWrongReference,
    'container-change-waiting-companyClosed': containerChangeWaitingCompanyClosed,
    'container-change-waiting-line': containerChangeWaitingLine,
    'container-change-waiting-notReady': containerChangeWaitingNotReady,
    'container-change-waiting-other': containerChangeWaitingOther,
    'container-change-eta-other': containerChangeEtaOther,
    'container-change-eta-policeControl': containerChangeEtaPoliceControl,
    'container-change-eta-tachoStop': containerChangeEtaTachoStop,
    'container-change-eta-trafficJam': containerChangeEtaTrafficJam,

// Custmers Stops
// ferryTunel
    'customerStop-ferryTunnel-eta-other': ferryTunnel,
    'customerStop-ferryTunnel-eta-trafficJam': customerStopTrafficJam,
    'customerStop-ferryTunnel-eta-policeControl': customerStopPliceControl,
    'customerStop-ferryTunnel-eta-tachoStop': customerStopTachoStop,

    'customerStop-ferryTunnel-arrived': ferryTunnelArrived,
    'customerStop-ferryTunnel-waiting-other': ferryTunnel,
    'customerStop-ferryTunnel-finished': ferryTunnelFinished,
    'customerStop-ferryTunnel-started': ferryTunnelStarted,

    'customerStop-ferryTunnel-waiting-wrongReference': customerStopWrongReference,
    'customerStop-ferryTunnel-waiting-companyClosed': customerStopCompanyClosed,
    'customerStop-ferryTunnel-waiting-notReady': customerStopNotReady,
    'customerStop-ferryTunnel-waiting-line': customerStopLine,

// service
    'customerStop-service-eta-other': service,
    'customerStop-service-eta-trafficJam': customerStopTrafficJam,
    'customerStop-service-eta-policeControl': customerStopPliceControl,
    'customerStop-service-eta-tachoStop': customerStopTachoStop,

    'customerStop-service-arrived': service,
    'customerStop-service-waiting-other': service,
    'customerStop-service-finished': serviceFinished,
    'customerStop-service-started': serviceStarted,

    'customerStop-service-waiting-wrongReference': customerStopWrongReference,
    'customerStop-service-waiting-companyClosed': customerStopCompanyClosed,
    'customerStop-service-waiting-notReady': customerStopNotReady,
    'customerStop-service-waiting-line': customerStopLine,

// homebase
    'customerStop-homebase-eta-other': homebase,
    'customerStop-homebase-eta-trafficJam': customerStopTrafficJam,
    'customerStop-homebase-eta-policeControl': customerStopPliceControl,
    'customerStop-homebase-eta-tachoStop': customerStopTachoStop,

    'customerStop-homebase-arrived': homebase,
    'customerStop-homebase-waiting-other': homebase,
    'customerStop-homebase-finished': homebaseFinished,
    'customerStop-homebase-started': homebaseStarted,

    'customerStop-homebase-waiting-wrongReference': customerStopWrongReference,
    'customerStop-homebase-waiting-companyClosed': customerStopCompanyClosed,
    'customerStop-homebase-waiting-notReady': customerStopNotReady,
    'customerStop-homebase-waiting-line': customerStopLine,

// equipmentPickUp
    'customerStop-equipmentPickUp-eta-other': equipmentPickUp,
    'customerStop-equipmentPickUp-eta-trafficJam': customerStopTrafficJam,
    'customerStop-equipmentPickUp-eta-policeControl': customerStopPliceControl,
    'customerStop-equipmentPickUp-eta-tachoStop': customerStopTachoStop,

    'customerStop-equipmentPickUp-arrived': equipmentPickUp,
    'customerStop-equipmentPickUp-waiting-other': equipmentPickUp,
    'customerStop-equipmentPickUp-finished': equipmentPickUpFinished,
    'customerStop-equipmentPickUp-started': equipmentPickUpStarted,

    'customerStop-equipmentPickUp-waiting-wrongReference': customerStopWrongReference,
    'customerStop-equipmentPickUp-waiting-companyClosed': customerStopCompanyClosed,
    'customerStop-equipmentPickUp-waiting-notReady': customerStopNotReady,
    'customerStop-equipmentPickUp-waiting-line': customerStopLine,

// secureParking
    'customerStop-secureParking-eta-other': secureParking,
    'customerStop-secureParking-eta-trafficJam': customerStopTrafficJam,
    'customerStop-secureParking-eta-policeControl': customerStopPliceControl,
    'customerStop-secureParking-eta-tachoStop': customerStopTachoStop,

    'customerStop-secureParking-arrived': secureParking,
    'customerStop-secureParking-waiting-other': secureParking,
    'customerStop-secureParking-finished': secureParkingFinished,
    'customerStop-secureParking-started': secureParkingStarted,

    'customerStop-secureParking-waiting-wrongReference': customerStopWrongReference,
    'customerStop-secureParking-waiting-companyClosed': customerStopCompanyClosed,
    'customerStop-secureParking-waiting-notReady': customerStopNotReady,
    'customerStop-secureParking-waiting-line': customerStopLine,

// Parking
    'customerStop-parking-eta-other': parking,
    'customerStop-parking-eta-trafficJam': customerStopTrafficJam,
    'customerStop-parking-eta-policeControl': customerStopPliceControl,
    'customerStop-parking-eta-tachoStop': customerStopTachoStop,

    'customerStop-parking-arrived': parking,
    'customerStop-parking-waiting-other': parking,
    'customerStop-parking-finished': parkingFinished,
    'customerStop-parking-started': parkingStarted,

    'customerStop-parking-waiting-wrongReference': customerStopWrongReference,
    'customerStop-parking-waiting-companyClosed': customerStopCompanyClosed,
    'customerStop-parking-waiting-notReady': customerStopNotReady,
    'customerStop-parking-waiting-line': customerStopLine,

// borderCustoms
    'customerStop-borderCustoms-eta-other': borderCustoms,
    'customerStop-borderCustoms-eta-trafficJam': customerStopTrafficJam,
    'customerStop-borderCustoms-eta-policeControl': customerStopPliceControl,
    'customerStop-borderCustoms-eta-tachoStop': customerStopTachoStop,

    'customerStop-borderCustoms-arrived': customerStopArrived,
    'customerStop-borderCustoms-waiting-other': borderCustoms,
    // 'customerStop-borderCustoms-finished': borderCustoms,
    'customerStop-borderCustoms-started': borderCustomsStarted,
    'customerStop-borderCustoms-finished': borderCustomsFinished,
    'customerStop-borderCustoms-waiting-wrongReference': customerStopWrongReference,
    'customerStop-borderCustoms-waiting-companyClosed': customerStopCompanyClosed,
    'customerStop-borderCustoms-waiting-notReady': customerStopNotReady,
    'customerStop-borderCustoms-waiting-line': customerStopLine,

// other
    'customerStop-other-eta-other': other,
    'customerStop-other-eta-trafficJam': customerStopTrafficJam,
    'customerStop-other-eta-policeControl': customerStopPliceControl,
    'customerStop-other-eta-tachoStop': customerStopTachoStop,

    'customerStop-other-arrived': other,
    'customerStop-other-waiting-other': other,
    'customerStop-other-finished': finished,
    'customerStop-other-started': otherStarted,

    'customerStop-other-waiting-wrongReference': customerStopWrongReference,
    'customerStop-other-waiting-companyClosed': customerStopCompanyClosed,
    'customerStop-other-waiting-notReady': customerStopNotReady,
    'customerStop-other-waiting-line': customerStopLine,


// Boek Stops
// service
    'boekStop-service-eta-other': boekStopServiceEta,
    'boekStop-service-eta-trafficJam': customerStopTrafficJam,
    'boekStop-service-eta-policeControl': customerStopPliceControl,
    'boekStop-service-eta-tachoStop': customerStopTachoStop,

    'boekStop-service-arrived': boekStopServiceEta,
    'boekStop-service-waiting-other': boekStopServiceEta,
    'boekStop-service-finished': boekStopServiceFinished,
    'boekStop-service-started': boekStopServiceStarted,

    'boekStop-service-waiting-wrongReference': customerStopWrongReference,
    'boekStop-service-waiting-companyClosed': customerStopCompanyClosed,
    'boekStop-service-waiting-notReady': customerStopNotReady,
    'boekStop-service-waiting-line': customerStopLine,
};
