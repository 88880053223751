import { observable, computed } from 'mobx';
import { Model, Store, Casts } from 'store/Base';
import { Customer } from './Customer';
import { Entity } from './Entity';
import { TrackedEquipmentStore } from './TrackedEquipment';
import { AllocationStore } from './Allocation';
import { TariffStore } from './Tariff';
import { UserStore } from './User';
import { ContractDocumentStore } from './ContractDocument';
import { SERVER_DATE_FORMAT } from 'helpers';
import moment from 'moment';
import { omit } from 'lodash';
import { camelCase } from 'lodash';
import { OWN_CURRENCY } from 'helpers';
import { showNotification } from '@code-yellow/spider';

export const FAKE_TYPE_DONT_SEND = 'dont send';
export const FAKE_TYPE_SEND_BY_EMAIL = 'email';
export const FAKE_TYPE_SEND_BY_POST = 'post';


export const DOCUMENTS_MOMENT_WHEN_DONT_SEND = 'dont send';
export const DOCUMENTS_MOMENT_WHEN_AFTER_FINALIZE = 'after finalize';
export const DOCUMENTS_MOMENT_WHEN_SEND_WITH_INVOICE = 'with invoice';
export const DOCUMENTS_MOMENT_WHEN = [
    DOCUMENTS_MOMENT_WHEN_SEND_WITH_INVOICE,
    DOCUMENTS_MOMENT_WHEN_AFTER_FINALIZE,
    DOCUMENTS_MOMENT_WHEN_DONT_SEND,
];

export const INVOICE_MOMENT_WHEN_AFTER_FINALIZE = 'after finalize';
export const INVOICE_MOMENT_WHEN_WEEKLY = 'weekly';
export const INVOICE_MOMENT_WHEN_2_WEEKLY = '2-weekly';
export const INVOICE_MOMENT_WHEN_4_WEEKLY = '4-weekly';
export const INVOICE_MOMENT_WHEN_MONTHLY = 'monthly';

export const INVOICE_MOMENT_WHEN = [
    INVOICE_MOMENT_WHEN_AFTER_FINALIZE,
    INVOICE_MOMENT_WHEN_WEEKLY,
    INVOICE_MOMENT_WHEN_2_WEEKLY,
    INVOICE_MOMENT_WHEN_4_WEEKLY,
    INVOICE_MOMENT_WHEN_MONTHLY,
];

export const INVOICE_MOMENT_UP_TO_AND_INCLUDING_SAME_DAY = 'same day';
export const INVOICE_MOMENT_UP_TO_AND_INCLUDING_PREVIOUS_DAY = 'previous day';
export const INVOICE_MOMENT_UP_TO_AND_INCLUDING_PREVIOUS_WEEK = 'previous week';
export const INVOICE_MOMENT_UP_TO_AND_INCLUDING_PREVIOUS_MONTH = 'previous month';

export const INVOICE_MOMENT_UP_TO_AND_INCLUDING = [
    INVOICE_MOMENT_UP_TO_AND_INCLUDING_SAME_DAY,
    INVOICE_MOMENT_UP_TO_AND_INCLUDING_PREVIOUS_DAY,
    INVOICE_MOMENT_UP_TO_AND_INCLUDING_PREVIOUS_WEEK,
    INVOICE_MOMENT_UP_TO_AND_INCLUDING_PREVIOUS_MONTH,
];
export const TYPE_TRUCK = 'truck';
export const TYPE_TRAILER = 'trailer';


export class Contract extends Model {
    static backendResourceName = 'contract';
    static channelTeamVariable = [
        TYPE_TRUCK,
        TYPE_TRAILER,
    ];

    @observable id = null;
    @observable name = '';
    @observable startDate = null;
    @observable endDate = null;
    @observable requiredDocuments = ['cmr'];
    @observable attachDocuments = ['cmr'];
    @observable allowedKmPerDay = null;

    // Some settings that determine how invoices should be rendered
    @observable allowMultipleLines = true;
    @observable sendInvoice = 'dont send';
    @observable useTemplate = false;
    @observable selfBilling = false; // false = Boek calc, true = Customer calc
    @observable includeDetails = false;
    @observable includeSpecification = false;
    @observable defaultInvoiceRemarks = '';
    @observable invoiceMomentWhen = 'after finalize'; // after finalize, weekly, 2-weekly, 4-weekly, monthly,
    @observable invoiceMomentDayOfWeek = 1; // 1-7 (Monday-Sunday)
    @observable invoiceMomentDayOfMonth = 1; // 1-31
    @observable invoiceMomentUpToAndIncluding = 'same day';
    @observable showCalculationMatrix = false;
    @observable useEquipmentModule = false;
    @observable activateTeams = false;
    @observable teamsId = '';
    @observable channelTeamVariable = [];
    @observable channelTeamsId = null; // on contract it is -- General -- channel
    @observable documentsMomentWhen = 'with invoice'; // after finalize, with invoice, dont send

    @observable invoiceEmailSubject = '';
    @observable invoiceEmailContent = '';
    @observable draftInvoiceEmailSubject = '';
    @observable draftInvoiceEmailContent = '';
    @observable proposedRouteRejectEmailSubject = '';
    @observable proposedRouteRejectEmailContent = '';

    @observable shortestRouteOptimizationLevel = 25;
    @observable normalRouteOptimizationLevel = 61;
    @observable fastestRouteOptimizationLevel = 100;

    // For now take the stupid approach and define templates for what we need
    // for status updates. See T24339.
    @observable noPlannedEmailSubject = '';
    @observable noPlannedEmailContent = '';
    @observable activityActivityLoadPlannedEmailSubject = '';
    @observable activityActivityLoadPlannedEmailContent = '';
    @observable activityActivityLoadEtaEmailSubject = '';
    @observable activityActivityLoadEtaEmailContent = '';
    @observable activityActivityLoadArrivedEmailSubject = '';
    @observable activityActivityLoadArrivedEmailContent = '';
    @observable activityActivityLoadWaitingEmailSubject = '';
    @observable activityActivityLoadWaitingEmailContent = '';
    @observable activityActivityLoadStartedEmailSubject = '';
    @observable activityActivityLoadStartedEmailContent = '';
    @observable activityActivityLoadFinishedEmailSubject = '';
    @observable activityActivityLoadFinishedEmailContent = '';

    @observable activityActivityUnloadPlannedEmailSubject = '';
    @observable activityActivityUnloadPlannedEmailContent = '';
    @observable activityActivityUnloadEtaEmailSubject = '';
    @observable activityActivityUnloadEtaEmailContent = '';
    @observable activityActivityUnloadArrivedEmailSubject = '';
    @observable activityActivityUnloadArrivedEmailContent = '';
    @observable activityActivityUnloadWaitingEmailSubject = '';
    @observable activityActivityUnloadWaitingEmailContent = '';
    @observable activityActivityUnloadStartedEmailSubject = '';
    @observable activityActivityUnloadStartedEmailContent = '';
    @observable activityActivityUnloadFinishedEmailContent = '';
    @observable activityActivityUnloadFinishedEmailSubject = '';

    @observable activityActivityTrailerChangePlannedEmailSubject = '';
    @observable activityActivityTrailerChangePlannedEmailContent = '';
    @observable activityActivityTrailerChangeEtaEmailSubject = '';
    @observable activityActivityTrailerChangeEtaEmailContent = '';
    @observable activityActivityTrailerChangeArrivedEmailSubject = '';
    @observable activityActivityTrailerChangeArrivedEmailContent = '';
    @observable activityActivityTrailerChangeWaitingEmailSubject = '';
    @observable activityActivityTrailerChangeWaitingEmailContent = '';
    @observable activityActivityTrailerChangeStartedEmailSubject = '';
    @observable activityActivityTrailerChangeStartedEmailContent = '';
    @observable activityActivityTrailerChangeFinishedEmailSubject = '';
    @observable activityActivityTrailerChangeFinishedEmailContent = '';

    @observable activityActivityPoiPlannedEmailSubject = '';
    @observable activityActivityPoiPlannedEmailContent = '';
    @observable activityActivityPoiEtaEmailSubject = '';
    @observable activityActivityPoiEtaEmailContent = '';
    @observable activityActivityPoiArrivedEmailSubject = '';
    @observable activityActivityPoiArrivedEmailContent = '';
    @observable activityActivityPoiWaitingEmailSubject = '';
    @observable activityActivityPoiWaitingEmailContent = '';
    @observable activityActivityPoiStartedEmailSubject = '';
    @observable activityActivityPoiStartedEmailContent = '';
    @observable activityActivityPoiFinishedEmailSubject = '';
    @observable activityActivityPoiFinishedEmailContent = '';

    @observable activityActivityContainerChangePlannedEmailSubject = '';
    @observable activityActivityContainerChangePlannedEmailContent = '';
    @observable activityActivityContainerChangeEtaEmailSubject = '';
    @observable activityActivityContainerChangeEtaEmailContent = '';
    @observable activityActivityContainerChangeArrivedEmailSubject = '';
    @observable activityActivityContainerChangeArrivedEmailContent = '';
    @observable activityActivityContainerChangeWaitingEmailSubject = '';
    @observable activityActivityContainerChangeWaitingEmailContent = '';
    @observable activityActivityContainerChangeStartedEmailSubject = '';
    @observable activityActivityContainerChangeStartedEmailContent = '';
    @observable activityActivityContainerChangeFinishedEmailSubject = '';
    @observable activityActivityContainerChangeFinishedEmailContent = '';

    @observable activityFinalizeEmailSubject = '';
    @observable activityFinalizeEmailContent = '';

    @observable pendingDisputesEmailSubject = '';
    @observable pendingDisputesEmailContent = '';

    // This is for only Save
    @observable autoUpdateForActivity = [];
    @observable autoUpdateForBoekStop = [];
    @observable autoUpdateForCustomerStop = [];

    // This is for Save & Send
    @observable autoUpdateForActivitySend = [];
    @observable autoUpdateForBoekStopSend = [];
    @observable autoUpdateForCustomerStopSend = [];

    @observable entityFee = 0;
    @observable euroClass = 5;
    @observable doubleTeam = false;
    @observable disputeToleranceMaxPercentage = 0;
    @observable disputeToleranceMaxAmount = 0;
    @observable invoiceableActivityCount = 0;

    @observable emailFromAddress = null;
    @observable emailFromName = null;

	@observable expectedTimeForLoad = 120;
	@observable expectedTimeForUnload = 120;
	@observable expectedTimeForPoi = 120;
	@observable expectedTimeForTrailerChange = 60;
	@observable expectedTimeForDriverTruckChange = 180;
	@observable expectedTimeForTruckSwap = 120;
	@observable expectedTimeForTruckDrop = 60;
	@observable expectedTimeForTanking = 30;
	@observable expectedTimeForHomebase = 60;
	@observable expectedTimeForTuv = 120;
	@observable expectedTimeForBorderCustoms = 60;
	@observable expectedTimeForFerryTunnel = 60;
	@observable expectedTimeForEquipmentPickUp = 60;
	@observable expectedTimeForService = 120;
	@observable expectedTimeForSecureParking = 60;
    @observable expectedTimeForParking = 60;
	@observable expectedTimeForOther = 60;

    @observable fcCode = OWN_CURRENCY;
    @observable useGbpTemplate = false;
    @observable ecmr = false;

    casts() {
        return {
            startDate: Casts.date,
            endDate: Casts.date,
        };
    }

    relations() {
        return {
            customer: Customer,
            entity: Entity,
            allocations: AllocationStore,
            users: UserStore,
            type: ContractType,
            tariffs: TariffStore,
            orderReferenceValidations: ContractOrderReferenceValidationStore,
            trackedEquipments: TrackedEquipmentStore,
            documents: ContractDocumentStore,
        };
    }

    toBackend(options) {
        return omit(super.toBackend(options), [
            'invoiceable_activity_count',
        ]);
    }

    get routeOptimizationLevels() {
        return [{
            optimizationLevel: this.shortestRouteOptimizationLevel,
            label: t('contract.field.shortestRouteOptimizationLevel.shortLabel'),
        }, {
            optimizationLevel: this.normalRouteOptimizationLevel,
            label: t('contract.field.normalRouteOptimizationLevel.shortLabel'),
        }, {
            optimizationLevel: this.fastestRouteOptimizationLevel,
            label: t('contract.field.fastestRouteOptimizationLevel.shortLabel'),
        }];
    }

    @computed
    get currentTariff() {
        if (this.tariffs && this.tariffs.length > 0) {
            return this.tariffs.at(0);
        }

        return null;
    }

    @computed
    get isFc() {
        return this.fcCode !== OWN_CURRENCY;
    }

    /**
     * Extract subject / content from contract settings.
     */
    getActivityStatusUpdateTemplate(activity) {
        return {
            subjectTemplate: this[camelCase(`activity_${activity.kind}_${activity.type}_${activity.status}_Email_Subject`)],
            contentTemplate: this[camelCase(`activity_${activity.kind}_${activity.type}_${activity.status}_Email_Content`)],
        };
    }

    hasActivityStatusUpdateTemplate(activity) {
        const { subjectTemplate, contentTemplate } = this.getActivityStatusUpdateTemplate(activity);

        if (!subjectTemplate || !contentTemplate) {
            return false;
        }

        return true;
    }

    getActiveTariff() {
        if (this.tariffs.length > 0) {
            const today = moment().startOf('day');
            const tariffs = this.tariffs.models.map(t => t);

            tariffs.sort((a, b) => {
                if (a.startDate < b.startDate) {
                    return 1;
                }

                if (a.startDate > b.startDate) {
                    return -1;
                }

                return 0;
            });

            return tariffs.find(tariff => {
                return tariff.startDate <= today;
            });
        }

        return undefined;
    }

    getTariffForActivity(activity) {
        if (!activity.finishedDatetime) {
            return undefined;
        }

        const activityDate = activity.finishedDatetime.clone().startOf('day');

        // Find the first tariff that starts before our activity finisheddatetime
        const tariff = this.tariffs.filter(tariff => tariff.startDate.clone().startOf('day') <= activityDate);

        return tariff[0]
    }

    getActivityParams() {
        const params = {};
        let offset = 0;

        switch (this.invoiceMomentWhen) {
            case INVOICE_MOMENT_WHEN_WEEKLY:
                switch (this.invoiceMomentUpToAndIncluding) {
                    case INVOICE_MOMENT_UP_TO_AND_INCLUDING_SAME_DAY:
                        offset = 0;
                        if (parseInt(moment().format('E')) === parseInt(this.invoiceMomentDayOfWeek)) {
                            params['.finalized_at:lte'] = moment().format(SERVER_DATE_FORMAT);
                        } else if (parseInt(moment().format('E')) >= parseInt(this.invoiceMomentDayOfWeek)) {
                            params['.finalized_at:lte'] = moment().isoWeekday(this.invoiceMomentDayOfWeek).format(SERVER_DATE_FORMAT);
                        } else {
                            params['.finalized_at:lte'] = moment().isoWeekday(this.invoiceMomentDayOfWeek - 7).format(SERVER_DATE_FORMAT);
                        }
                        break;
                    case INVOICE_MOMENT_UP_TO_AND_INCLUDING_PREVIOUS_DAY:
                        offset = 1;
                        if (parseInt(moment().format('E')) === parseInt(this.invoiceMomentDayOfWeek)) {
                            params['.finalized_at:lte'] = moment().isoWeekday(this.invoiceMomentDayOfWeek - offset).format(SERVER_DATE_FORMAT);
                        } else if (parseInt(moment().format('E')) >= parseInt(this.invoiceMomentDayOfWeek)) {
                            params['.finalized_at:lte'] = moment().isoWeekday(this.invoiceMomentDayOfWeek - offset).format(SERVER_DATE_FORMAT);
                        } else {
                            params['.finalized_at:lte'] = moment().isoWeekday(this.invoiceMomentDayOfWeek - offset - 7).format(SERVER_DATE_FORMAT);
                        }
                        break;
                    case INVOICE_MOMENT_UP_TO_AND_INCLUDING_PREVIOUS_WEEK:
                        if (parseInt(moment().format('E')) >= parseInt(this.invoiceMomentDayOfWeek)) {
                            // Go to last week.
                            params['.finalized_at:lte'] = moment().isoWeekday(7).subtract(1, 'week').format(SERVER_DATE_FORMAT);
                        } else {
                            // Go to 2 weeks ago.
                            params['.finalized_at:lte'] = moment().isoWeekday(7).subtract(2, 'week').format(SERVER_DATE_FORMAT);
                        }
                        break;
                    case INVOICE_MOMENT_UP_TO_AND_INCLUDING_PREVIOUS_MONTH:
                        // This shouldn't happen? Weekly invoice previous month is a bit weird.
                        break;
                    default:
                        break;

                }
                break;
                default:
                    break;
            case INVOICE_MOMENT_WHEN_MONTHLY:
                switch (this.invoiceMomentUpToAndIncluding) {
                    case INVOICE_MOMENT_UP_TO_AND_INCLUDING_SAME_DAY:
                        if (parseInt(moment().format('D')) < parseInt(this.invoiceMomentDayOfMonth)) {
                            params['.finalized_at:lte'] = moment().subtract(1,'months').date(this.invoiceMomentDayOfMonth).format(SERVER_DATE_FORMAT);
                        } else if (parseInt(moment().format('D')) >= parseInt(this.invoiceMomentDayOfMonth)) {
                            params['.finalized_at:lte'] = moment().date(this.invoiceMomentDayOfMonth).format(SERVER_DATE_FORMAT);
                        }
                        break;
                    case INVOICE_MOMENT_UP_TO_AND_INCLUDING_PREVIOUS_DAY:
                        // TODO add previous day code
                        break;
                    case INVOICE_MOMENT_UP_TO_AND_INCLUDING_PREVIOUS_WEEK:
                        // TODO add previous week code
                        break;
                    default:
                        break;
                }
                break;
        }

        return params;
    }

    activateTeamsIntegration() {
        return this.api.get(this.url + 'activate_teams_integration/').then(res => {
            if (res['response'] === 'ok') {
                showNotification({ type: 'info', message: t('Teams integration activated') });
                this.activateTeams = true;
            } else {
                showNotification({ type: 'error', message: t('Some issue was encountered during integration with Microsoft Teams') });
                this.activateTeams = false;
                this.teamsId = null;
                this.save();
            }
        }).catch(error => {
            console.log(error);
        });
    }
}

export class ContractStore extends Store {
    Model = Contract;
    static backendResourceName = 'contract';

    // {save-users-copy-paste}
    saveUsers() {
        const modelOptions = { fields: ['id', 'users'] };
        const data = this.map(m => m.toBackend(modelOptions));
        return this.api.put(this.url(), {
            data,
        });
    }
}

export class ContractType extends Model {
    static backendResourceName = 'contract_type';

    @observable id = null;
    @observable name = '';
}

export class ContractTypeStore extends Store {
    Model = ContractType;
    static backendResourceName = 'contract_type';
}

export const CONTRACT_ORDER_REFERENCE_VALIDATION_TYPE_LENGTH = 'length';
export const CONTRACT_ORDER_REFERENCE_VALIDATION_TYPE_PREFIX = 'prefix';
export const CONTRACT_ORDER_REFERENCE_VALIDATION_TYPE_SUFFIX = 'suffix';
export const CONTRACT_ORDER_REFERENCE_VALIDATION_TYPE = [
    CONTRACT_ORDER_REFERENCE_VALIDATION_TYPE_LENGTH,
    CONTRACT_ORDER_REFERENCE_VALIDATION_TYPE_PREFIX,
    CONTRACT_ORDER_REFERENCE_VALIDATION_TYPE_SUFFIX,
];

export class ContractOrderReferenceValidation extends Model {
    static backendResourceName = 'contract_order_reference_validation';

    @observable id = null;
    @observable type = CONTRACT_ORDER_REFERENCE_VALIDATION_TYPE_PREFIX;
    @observable content = '';
    @observable length = null;
}

export class ContractOrderReferenceValidationStore extends Store {
    Model = ContractOrderReferenceValidation;
    static backendResourceName = 'contract_order_reference_validation';
}
