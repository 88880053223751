export default `
{{customerId}} / {{trailerPlate}} waiting for Container Change {{currentLocation}}



{{customerId}} / {{trailerPlate}} is waiting for Container Change in {{currentLocation}}
Reason: other

Time IN: {{currentArrivalTime}}

{{timezoneOffsetHoursWithCest}}{{waitingRateApplies}}

Best Regards,
{{userFullName}}
Boekestijn Transport Service
{{userEmailSignature}}
`;