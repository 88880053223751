import { observable } from 'mobx';
import { Model, Store } from 'store/Base';
import { Customer } from './Customer';


export class Container extends Model {
    static backendResourceName = 'container';

    @observable id = null;
    @observable licensePlate = '';
    @observable remarks = '';
    @observable _isPickup = true;


    relations() {
        return {
            customer: Customer,
        };
    }

    parse(data) {
        data._isPickup = data.id !== null;

        return super.parse(data);
    }

    updateIsPickUp() {
        this._isPickup = this.id !== null;
    }

    casts() {
        return {
            _isPickup: {
                parse: (attr, value) => {
                    return !!this.id;
                },
                toJS(attr, value) {

                    return value;
                }
            },
        };
    }

    showPickupForm() {
        return !!this.id || this._isPickup;
    }

    licensePlateIsRequired(activity) {
        return activity.type === 'container change' && this.showPickupForm();
    }

    validate(activity) {
        if (this.licensePlateIsRequired(activity)) {
            return !!this.licensePlate;
        }
        return true;
    }
}

export class ContainerStore extends Store {
    Model = Container;
    static backendResourceName = 'container';
}
