import React from 'react';
import PropTypes from 'prop-types';
import IconLoad from './Icon/Load';
import IconUnload from './Icon/Unload';
import IconTanking from './Icon/Tanking';
import IconSecureParking from './Icon/SecureParking';
import IconBannedParking from './Icon/BannedParking';
import IconDriverChange from './Icon/DriverChange';
import IconTruckChange from './Icon/TruckChange';
import IconTrailerChange from './Icon/TrailerChange';
import IconContainerChange from './Icon/ContainerChange';
import IconFerrytunnel from './Icon/Ferrytunnel';
import IconEquipmentPickup from './Icon/EquipmentPickup';
import IconTruckSwap from './Icon/TruckSwap';
import IconAllocationExit from './Icon/AllocationExit';
import IconPoi from './Icon/Poi';
import { IconAdjust, IconHome, IconBuild } from 're-cy-cle';
import { Icon  } from '@code-yellow/spider';
import { theme } from 'styles';
import { TYPE_CHANGE, TYPE_BORDER, TYPE_TACHO_CALIBRATION, TYPE_TUV, TYPE_TRUCK_DROP, TYPE_ALLOCATION_EXIT } from 'store/Activity';
import { getCurrencySymbolFromCode } from 'helpers';
import styled from 'styled-components';

const StyledRestrictedZonePoint = styled.span`
    color: white;
    font-size: 15px;
    font-weight: bold;
`

const RestrictedZoneIcon = type => {
    return <StyledRestrictedZonePoint>{type.slice(-1)}</StyledRestrictedZonePoint>
}

const TypeIcon = ({ type, width, height, color = theme.primaryColor, price, certification, parking, banned }) => {

    const parkingNumberStyle = {
        position: 'absolute',
        top: '11px',
        right: '-3px',
        color: 'white',
        width: '25px',
        height: '16px',
        fontSize: '11px',
        fontWeight: 'bold',
      };

    const IconWithNumber = props => {
        if (props.banned) {
            return (
                <IconBannedParking data-test-icon-parking {...props}/>
              );
        } else if (props.certification === 'TSR1' || props.certification === 'TSR2') {
            const certNumber = (props.certification === 'TSR1' ? 1 : 2 )
            return (
                <div style={{ position: 'relative' }}>
                  <IconSecureParking data-test-icon-parking {...props}/>
                  <div style={parkingNumberStyle}>{certNumber}</div>
                </div>
              );
        } else if (props.parking) {
            const currencySymbol = props.parking.currency ? getCurrencySymbolFromCode(props.parking.currency) : ''
            if (props.parking.dataSource === 'travis' || !props.parking.isFree) {
                return (
                    <div style={{ position: 'relative' }}>
                        <IconSecureParking data-test-icon-parking {...props}/>
                        <div style={{
                            position: 'absolute',
                            top: '17px',
                            right: '-11px',
                            color: `${theme.primaryColor}`,
                            width: '25px',
                            height: '16px',
                            fontSize: '15px',
                        }}>{currencySymbol}</div>
                    </div>
                  );
            } else {
                return (
                    <IconSecureParking data-test-icon-parking {...props}/>
                  );
            }
        } else {
            return (
                <IconSecureParking data-test-icon-parking {...props}/>
              );
        }
    };

    const props = { width, height, color };
    switch (type) {
        case 'load':
            return <IconLoad {...props} />;
        case 'unload':
            return <IconUnload {...props} />;
        case 'tanking':
            return <IconTanking data-test-icon-tanking  price={price} {...props} />;
        case 'secure parking':
            return <IconSecureParking {...props} />;
        case 'parking':
            return <IconWithNumber data-test-icon-parking certification={certification} parking={parking} banned={banned} {...props} />;
        case 'ferry-tunnel':
            return <IconFerrytunnel {...props} />;
        case 'equipment pick-up':
            return <IconEquipmentPickup {...props} />;
        case 'truck change':
            return <IconTruckChange {...props} />;
        case 'driver change':
            return <IconDriverChange {...props} />;
        case 'trailer change':
            return <IconTrailerChange {...props} />;
        case 'container change':
            return <IconContainerChange {...props} />;
        case 'homebase':
            return <IconHome {...props} />;
        case 'service':
            return <IconBuild {...props} />;
        case 'truck swap':
            return <IconTruckSwap {...props} />;
        case 'other':
            return <IconAdjust {...props} />;
        case 'pointA':
            return RestrictedZoneIcon(type);
        case 'pointB':
            return RestrictedZoneIcon(type);
        case 'pointC':
            return RestrictedZoneIcon(type);
        case 'pointD':
            return RestrictedZoneIcon(type);
        case 'poi':
            return <IconPoi {...props} />;
        case TYPE_ALLOCATION_EXIT:
            return <IconAllocationExit {...props} />;
        case TYPE_CHANGE:
            return <Icon fitted name="refresh" {...props} />;
        case TYPE_BORDER:
            return <Icon fitted name="map signs" {...props} />;
        case TYPE_TRUCK_DROP:
            return <Icon fitted name="sign-out" {...props} />;
        case TYPE_TACHO_CALIBRATION:
            return <span style={{ color: 'white', fontSize: '10px', fontWeight: 'bold' }}>TACH</span>;
        case TYPE_TUV:
            return <span style={{ color: 'white', fontSize: '10px', fontWeight: 'bold' }}>TUV</span>;
        case 'crosshairs':
            return <Icon fitted name="crosshairs" style={{ color: 'white' }} {...props} />;
        default:
            return null;
    }
};

TypeIcon.propTypes = {
    type: PropTypes.string,
    color: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    price: PropTypes.string,
    certification: PropTypes.string,
    parking: PropTypes.object,
    banned: PropTypes.bool,
};

TypeIcon.defaultProps = {
    color: theme.primaryColor, // Should use @withTheme...
    width: '30px',
    height: '30px',
};

export default TypeIcon;
