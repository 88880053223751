import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable, action } from 'mobx';
import { Form, Grid, Table, Modal, Icon, Button, Image, Radio, Feed, Popup, Label } from 'semantic-ui-react';
import { TargetTextInput, TargetTextArea, getFileTypeFromUrl } from '@code-yellow/spider';
import { User } from 'store/User';
import { Declaration, DeclarationStore } from 'store/Declaration';
import { DATETIME_FORMAT, DATE_SHORT_FORMAT } from 'helpers';
import { Menu } from 'semantic-ui-react'
import { CostProductStore } from 'store/CostProduct';
import { RightDivider  } from '@code-yellow/spider';
import { CancelButton, SaveButton, IconButton  } from '@code-yellow/spider';
import { ItemButton } from 'component/AdminOverview';
import moment from 'moment';
import { showSaveNotification, showNotification } from '@code-yellow/spider';
import { Document } from 'store/Document';
import ReactCrop from 'react-image-crop';
import { api } from 'store/Base';
import { formatMoney } from 'helpers';
import TargetMoneyInput from 'component/TargetMoneyInput';
import { CostProduct } from 'store/CostProduct';
import HistoryItem from 'component/History/Item';
import { urlEncodeObject  } from '@code-yellow/spider';
import {
    LabelSwitcherRight,
    LabelSwitcherLeft,
    StyledTypeSpan,
    TypeCheck,
    StyledFilePreview,
    SingleFileBox,
    UploadImageButton,
    StyledTableRow,
    StyledTableCell,
    PrimaryWrapper,
    ThertriaryWrapper,
    PicAndMenuContainer,
    PicAndMenuContainerModal,
    Wrapper,
    StyledLoader,
    MainFileViewWrapper,
    SimilarBorder,
    SimilarSign,
} from './DeclarationAiHelpersAndStyles';


const MISMATCHED_COLOR = 'rgb(91, 91, 91)'
const BEFORE_CORRECT_COLOR = 'rgb(30, 186, 64)'
const AFTER_CORRECT_COLOR = 'rgb(0, 128, 0)'
const BEFORE_ORANGE_COLOR = 'rgb(245, 118, 0)'
const AFTER_ORANGE_COLOR = 'rgb(201, 109, 0)'
const SIMILAR_COLOR = 'rgb(169, 50, 50)'

@observer
export default class DeclarationSeparatedDraw extends Component {
    static propTypes = {
        declaration: PropTypes.instanceOf(Declaration).isRequired,
        currentUser: PropTypes.instanceOf(User).isRequired,
        afterSave: PropTypes.func.isRequired,
        afterDeclarationSave: PropTypes.func.isRequired,
        groupedDeclarationStore: PropTypes.instanceOf(DeclarationStore).isRequired,
        onDateMatch: PropTypes.func,
        onChangeTypeFromAiToUser: PropTypes.func,
    }

    @observable showSystemHistory = false;
    toggleSystemHistory = () => {
        const { declaration } = this.props;
        this.showSystemHistory = !this.showSystemHistory;

        if (this.showSystemHistory) {
            declaration.fetchHistory();
        }
    }

    renderHistory() {
        const { declaration, currentUser } = this.props;

        return (
            <Feed data-test-history>
                {declaration._history.map(commentOrHistory => (
                    <HistoryItem data-test-declaration-history-content
                        currentUser={currentUser}
                        history={commentOrHistory}
                    />
                ))}
            </Feed>
        );
    }

    @observable crop = {
        x: 0,
        y: 0,
        width: 0,
        height: 0,
    };

    @observable pixelCrop = {
        x: 0,
        y: 0,
        width: 10,
        height: 10,
    };
    @observable fullScreen = false;
    @observable isCropping = false;
    @observable selectedDocument = null;

    @observable costProductStore = new CostProductStore({
        relations: ['entities'],
        params: {
            '.cost_type': 'road',
            order_by: 'description',
        }
    });

    @observable typeMatch = false;
    @observable amountMatch = false;
    @observable dateMatch = false;
    @observable selectedAmountOption = 'ai';
    @observable selectedTypeOption = 'ai';
    @observable missmatchedAiAmount = false;
    @observable missmatchedDriverAmount = false;
    @observable missmatchedDriverType = false;
    @observable missmatchedAiType = false;
    @observable ocrCurrencyCode = '';
    @observable similarDeclaration = false;
    @observable similarDriver = false;
    @observable aiResultAvailable = false;
    @observable aiResults = null;
    @observable aiAmount = null;
    @observable aiType = null;
    @observable aiCostProduct = null;
    @observable ocrDeclaration = null;
    @observable alreadyCheckAi = false;
    @observable aiMulticurrency = false

    @observable isOpen = false;

    open = (doc) => {
        this.selectedDocument = doc
        this.isOpen = true
    }
    close = () => this.isOpen = false;

    changeTypeSwitcher = (switcher=null) => {
        const { declaration, onChangeTypeFromAiToUser } = this.props;

        if (!!switcher) {
            this.selectedTypeOption = this.selectedTypeOption === 'user' ? 'ai' : 'user';
        }

        if (this.missmatchedAiType) {
            this.selectedTypeOption = 'user';
        } else if (this.missmatchedDriverType) {
            this.selectedTypeOption = 'ai';
        }

        if (!this.missmatchedAiType && this.aiType && !this.typeMatch && this.aiCostProduct) {
            const userCostProductId = declaration.oldCostProduct.id ? declaration.oldCostProduct.id : declaration.costProduct.id
            const costProduct = new CostProduct(
                    { id: this.selectedTypeOption === 'ai' ? this.aiCostProduct : userCostProductId},
                );

            if (costProduct.id) {
                costProduct.fetch().then(() => {
                    declaration.roadCost.setInput('costProduct', costProduct)
                    declaration.setInput('costProduct', costProduct)
                })
            }
        }

        this.selectedTypeOption === 'ai' ? declaration.setInput('aiCostProductSelected', true) : declaration.setInput('aiCostProductSelected', false)

        if (this.selectedTypeOption === 'user') {
            onChangeTypeFromAiToUser(this.aiType)
        }
    }

    afterChangeFcBySwitcher = () => {
        const { declaration } = this.props;

        if (declaration.roadCost.isOwnCurrency) {
            declaration.roadCost.syncOc();
        }

        declaration.roadCost.recalculateVat();
        declaration.roadCost.syncNet();
    }

    @action
    changeAmountSwitcher = (switcher=null) => {
        const { declaration } = this.props;

        if (!!switcher) {
            this.selectedAmountOption = this.selectedAmountOption === 'user' ? 'ai' : 'user';
        }

        const newValue = this.selectedAmountOption === 'user' ? declaration.quantity : this.aiAmount;

        if (this.missmatchedAiAmount) {
            this.selectedAmountOption = 'user';
        } else if (this.missmatchedDriverAmount) {
            this.selectedAmountOption = 'ai';
        }

        if (!this.missmatchedAiAmount && !this.aiMulticurrency) {
            declaration.roadCost.setInput('grossFc', newValue);
            declaration.roadCost.recalculateValuesForDeclaration();
            declaration.roadCost.recalculateValuesForDeclarationEurOc();
            declaration.roadCost.recalculateValuesForDeclarationEurFc();
            this.afterChangeFcBySwitcher();
        } else if (!this.missmatchedAiAmount & this.aiMulticurrency & !this.amountMatch) {
            declaration.roadCost.setInput('netOc',  newValue)
            declaration.roadCost.setInput('grossOc',  newValue)
        }
    };

    declarationStatus = (declaration) => {
        if (this.selectedDocument) {
           if (this.selectedDocument.status === 'approved by customer service') {
            return 'Approved'
           } else if (this.selectedDocument.status === 'rejected') {
            return 'Rejected'
           }
        }
        return 'Pending'
    }

    @observable multiCurrencyRate = null

    renderExchandeDiffPopup = (declaration) => {
        const percentageDiff = (simpledExchange, multiCurrencyRate) => {
            const diff = Math.abs(simpledExchange - multiCurrencyRate);
            const average = (simpledExchange + multiCurrencyRate) / 2;
            const result = (diff / average) * 100;
            return parseFloat(result.toFixed(2));
        }

        let simpledExchange = null;
        let multiCurrencyRate = null;

        if (declaration.simpledTransaction && declaration.simpledTransaction.originalTxnAmount && declaration.simpledTransaction.billingAmount) {
            simpledExchange = declaration.simpledTransaction.originalTxnAmount / declaration.simpledTransaction.billingAmount
        }

        if (this.multiCurrencyRate) {
            multiCurrencyRate = this.multiCurrencyRate.at(0).rate
        }

        if (simpledExchange && multiCurrencyRate) {
            const diff = percentageDiff(parseFloat(simpledExchange.toFixed(3)), parseFloat(multiCurrencyRate))
            const result = () => {
                return (
                    <div>
                        <div>{'Multi-currency and simplified differences'}</div>
                        <div>{`${diff}% | ${parseFloat((simpledExchange * declaration.quantityFc) - (multiCurrencyRate* declaration.quantityFc)).toFixed(2)} Eur`}</div>
                    </div>
                )
            }

            if (diff >= 1) {
                return (<Popup data-test-multicurrency-in-declaration-info
                    content={result()}
                    trigger={<Icon data-test-multicurrency-in-declaration name='exclamation triangle' color='orange'/>}
                    position='top left'
                />)
            } return
        }
    }

    exchangeDiff() {
        const { declaration } = this.props;

        const percentageDiff = (simpledExchange, multiCurrencyRate) => {
            const diff = Math.abs(simpledExchange - multiCurrencyRate);
            const average = (simpledExchange + multiCurrencyRate) / 2;
            const result = (diff / average) * 100;
            return parseFloat(result.toFixed(2));
        }

        let simpledExchange = null;
        let multiCurrencyRate = null;

        if (declaration.simpledTransaction.originalTxnAmount && declaration.simpledTransaction.billingAmount) {
            simpledExchange = declaration.simpledTransaction.originalTxnAmount / declaration.simpledTransaction.billingAmount
        }

        if (this.multiCurrencyRate) {
            multiCurrencyRate = this.multiCurrencyRate.at(0).rate
        }

        if (simpledExchange && multiCurrencyRate) {
            return percentageDiff(parseFloat(simpledExchange.toFixed(3)), parseFloat(multiCurrencyRate))
        }
    }

    extractDataFromAiResults(data) {
        const { onDateMatch } = this.props;

        this.typeMatch = data['correct_ai_type'];
        this.amountMatch = data['correct_ai_amount'];
        this.dateMatch = data['correct_date'];
        this.aiType = data['ocr_category'];
        this.missmatchedAiAmount = data['missmatched_amount'];
        this.missmatchedAiType = data['missmatched_ai_type'];
        this.ocrCurrencyCode = data['ocr_currency_code'];
        this.ocrDeclaration = data['ocr_declaration'];
        this.aiAmount = data['ocr_amount'];
        this.aiMulticurrency = data['multicurrency'];

        if (data['ocr_similar_declaration'] && data['ocr_similar_driver']) {
            this.similarDeclaration = data['ocr_similar_declaration'];
            this.similarDriver = data['ocr_similar_driver'];
        }

        if (data['ocr_cost_product']) {
            this.aiCostProduct = data['ocr_cost_product'];
        }

        if (this.dateMatch) {
            onDateMatch();
        }
    }

    renderAiResultsForRejectedOrApprovedDeclaration() {
        const { declaration, onDateMatch } = this.props;
        // somehow we are able to not fetch check_ai_results to correct render info for rejected/approved

        if (declaration.aiAvailable) {
            this.aiResultAvailable = true;
            this.ocrDeclaration = declaration.id;

            if (declaration.aiCostProductSelected) {
                this.selectedTypeOption = 'ai';
                this.aiType = declaration.costProduct.description;
            } else {
                this.selectedTypeOption = 'user';
            }

            if (declaration.oldCostProduct && declaration.oldCostProduct.id === declaration.costProduct.id && this.selectedTypeOption === 'ai') {
                this.typeMatch = true;
            }

            if (!declaration.oldCostProduct.id && this.selectedTypeOption === 'user') {
                this.aiType = 'Empty'
            }

            if (declaration.aiDateMatch) {
                onDateMatch();
            }

            if (declaration.aiQuantitySelected) {
                this.selectedAmountOption = 'ai';
                this.aiAmount = declaration.quantity;
                if (declaration.quantity === declaration.oldQuantity) {
                    this.amountMatch = true;
                }
            } else {
                this.selectedAmountOption = 'user';
                if (declaration.quantity !== declaration.oldQuantity) {
                    this.amountMatch = true;
                }
            }
        }
    }

    maybeFetchDeclarationAiResults() {
        const { declaration } = this.props;

        if (declaration.id) {
            this.isLoadingAiResults = true;

            if (!declaration.rejectedOrApproved || (this.ocrDeclaration && this.ocrDeclaration === declaration.id)) {

                if (declaration.aiAvailable) {
                    declaration.aiCostProductSelected ? this.selectedTypeOption = 'ai' : this.selectedTypeOption = 'user'
                    declaration.aiQuantitySelected ? this.selectedAmountOption = 'ai' : this.selectedAmountOption = 'user'
                }

                declaration.checkAiResults()
                .then((data) => {
                    if (data.data) {
                        this.alreadyCheckAi = true;
                        this.aiResultAvailable = true;
                        this.aiResults = data.data;

                        this.extractDataFromAiResults(data.data)

                        if (!declaration.quantity) {
                            this.missmatchedDriverAmount = true;
                        }
                        if (!declaration.costProduct.id) {
                            this.missmatchedDriverType = true;
                        }

                        if (!declaration.aiAvailable) {
                            this.changeAmountSwitcher(null);
                        }

                        if (!declaration.oldCostProduct.id) {
                            const oldQuantity = declaration.quantity;
                            const fakeCostProduct = new CostProduct(
                                { id: this.missmatchedDriverType ? null : declaration.costProduct.id},
                            );
                            declaration.setInput('oldQuantity' , oldQuantity)
                            fakeCostProduct.fetch().then(() => {
                                if (!this.missmatchedAiType) {
                                    declaration.oldCostProduct = fakeCostProduct
                                }
                                }
                            )
                        }
                        if (!this.typeMatch && !declaration.aiAvailable) {
                            this.changeTypeSwitcher();
                        }
                        declaration.setInput('aiAvailable', true)
                    }
                })
                .catch((error) => {
                    console.error("Error during checking Ai Results", error);
                    this.isLoadingAiResults = false;
                    this.alreadyCheckAi = true;
                })
                .finally(() => {
                    this.alreadyCheckAi = true;
                    this.isLoadingAiResults = false;
                });
            } else if (declaration.rejectedOrApproved) {
                this.renderAiResultsForRejectedOrApprovedDeclaration()
                this.isLoadingAiResults = false;
            } else {
                this.isLoadingAiResults = false;
                this.alreadyCheckAi = true;
            }
        }
    }

    componentDidMount() {
        const { declaration } = this.props;

        this.maybeFetchDeclarationAiResults()

        if (declaration.documentsToDisplay.length !== 0) {
            const notRejectedDocument = declaration.documentsToDisplay.find(document => document.status !== 'rejected')
            if (notRejectedDocument) {
                this.selectedDocument = notRejectedDocument
            }
        }

        const date = declaration.createdAt ? declaration.createdAt : moment()
        declaration.exchangeMoney(date, declaration.currency)
        .then((currencyStore) => {
            this.multiCurrencyRate = currencyStore;
        })
    }

    componentDidUpdate() {
        const { declaration } = this.props;

        if (((this.aiResultAvailable && this.ocrDeclaration && this.ocrDeclaration !== declaration.id) || !this.aiResultAvailable) && !this.alreadyCheckAi) {
            this.maybeFetchDeclarationAiResults();
        }

        if (this.selectedDocument) {
            if (!declaration.documentsToDisplay.find(doc => doc.id === this.selectedDocument.id)) {
                if (declaration.documentsToDisplay.length !== 0) {
                    const notRejectedDocument = declaration.documentsToDisplay.find(document => document.status !== 'rejected')
                    if (notRejectedDocument) {
                        this.selectedDocument = notRejectedDocument
                    }
                }
            }
            if (!declaration.documentsToDisplay.find(doc => doc.id === this.selectedDocument.id) && declaration.documentsToDisplay.length === 0) {
                this.selectedDocument = null;
            }
        }


        if (declaration.documentsToDisplay.length !== 0 && !this.selectedDocument) {
            this.selectedDocument = declaration.documentsToDisplay[0]
        }
    }

    uploadImage = (files, invalidFiles) => {
        const { declaration } = this.props;

        if (files) {
            files.forEach((file) =>{
                const doc = new Document({
                    type: 'declaration',
                    status: 'approved by planner',
                    scannedAt: moment(),
                    truck: declaration.fuelCost.truck.id ? declaration.fuelCost.truck.toJS() : declaration.roadCost.truck.toJS(),
                }, {
                    relations: ['truck'],
                });

                if (file.type === 'application/pdf') {
                    doc.setInput('pdfFile', file);
                } else {
                    doc.setInput('file', file);
                }

                doc.save().then(() => {
                    this.props.declaration.documents.chooseDocument(doc);
                    showSaveNotification();
                });
            });
        }
    };

    handleSelect = direction => {
        const { declaration } = this.props;

        if (declaration.documentsToDisplay.length !== 0 && this.selectedDocument) {
            let index = declaration.documentsToDisplay.findIndex(item => item.id === this.selectedDocument.id)

            if (direction === 'right') {
                if (declaration.documentsToDisplay.length === index + 1) {
                    index = 0
                } else {
                    index = index + 1
                }
            } else if (direction === 'left') {
                if (declaration.documentsToDisplay.length === 0) {
                    index = declaration.documentsToDisplay.length
                } else {
                    index = index - 1
                }
            }

            this.selectedDocument = declaration.documentsToDisplay[index]
        }
    };

    renderNewImage(doc, index) {
        let type = '';
        let url = '';

        if (doc.file) {
            type = getFileTypeFromUrl(doc.file)
            url = doc.file

            const isFirst = index === 0;

            const isSimilar = isFirst && this.similarDeclaration && this.similarDriver

            return (
                <SingleFileBox
                    data-test-document-box
                    onClick={() => this.open(doc)}
                    style={isSimilar ? {border: 'none'} : {}}
                >
                    <StyledFilePreview
                        style={isSimilar ? {
                            border: '5px solid rgba(201, 51, 51, 1)',
                            borderRadius: '8px',
                            boxShadow: '0px 0px 10px 5px rgba(201, 51, 51, 0.5)',
                            position: 'relative',
                            } : {}
                        }
                        type={type}
                        url={url}
                    />
                    {isSimilar && (
                        <SimilarBorder style={{ WebkitMaskImage: 'radial-gradient(circle, rgba(0,0,0,1) 80%, rgba(0,0,0,0) 100%)'}}>
                            <SimilarSign data-test-similar>
                                !
                            </SimilarSign>
                        </SimilarBorder>
                        )}
                </SingleFileBox>
            )
        }
    }

    renderImage = () => {
        if (this.selectedDocument) {
            if (this.isOpen) {
                return (
                    <React.Fragment>
                        {!this.isCropping
                            ? <PicAndMenuContainerModal><Image centered data-test-selected-document style={{ maxWidth: '550px', maxHeight: '550px', height: 'auto', width: 'auto' }} src={this.selectedDocument.file}/>{this.renderPicChangeMenu()}</PicAndMenuContainerModal>
                            : (
                                <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                                    <ReactCrop
                                        src={this.selectedDocument.file}
                                        crop={this.crop}
                                        onChange={(crop, pixelCrop) => {
                                            this.crop = crop;
                                            this.pixelCrop = pixelCrop;
                                        }}
                                    />
                                </div>
                            )
                        }
                    </React.Fragment>
                );
            } else {
                return (
                    <React.Fragment>
                        {!this.isCropping
                            ? <PicAndMenuContainer><Image centered data-test-selected-document style={{ maxWidth: '115px', maxHeight: '115px', height: 'auto', width: 'auto' }} src={this.selectedDocument.file} onClick={() => this.open()} />{this.renderPicChangeMenu()}</PicAndMenuContainer>
                            : (
                                <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                                    <ReactCrop
                                        src={this.selectedDocument.file}
                                        crop={this.crop}
                                        onChange={(crop, pixelCrop) => {
                                            this.crop = crop;
                                            this.pixelCrop = pixelCrop;
                                        }}
                                    />
                                </div>
                            )
                        }
                    </React.Fragment>
                );
            }
        }
    }

    handleSharpen = (sharpness) => {
        if (this.selectedDocument) {
            this.selectedDocument.sharpen(sharpness)
            .then(() => this.selectedDocument.fetch())
            .then(showSaveNotification)
            .catch(() => { });
        }
    };

    handleCrop = () => {
        const toggleCrop = () => {
            this.isCropping = !this.isCropping;
        }
        if (this.selectedDocument) {
            if (this.isCropping) {
                return this.selectedDocument.crop(this.pixelCrop)
                    .then(showSaveNotification)
                    .then(toggleCrop);
            }
        }
        toggleCrop();
    }

    handleBrighten = (brightness) => {
        if (this.selectedDocument) {
            this.selectedDocument.brighten(brightness)
                .then(() => this.selectedDocument.fetch())
                .then(showSaveNotification)
                .catch(() => { });
        }
    };

    handleRotate = () => {
        if (this.selectedDocument) {
            this.selectedDocument.rotate(90)
                .then(() => this.selectedDocument.fetch())
                .then(showSaveNotification)
                .catch(() => { });
        }
    };

    handleReset = () => {
        if (this.selectedDocument) {
            this.selectedDocument.reset()
                .then(() => this.selectedDocument.fetch())
                .then(showSaveNotification)
                .catch(() => { });
        }
    };

    requestScan = () => {
        const { declaration } = this.props;
        api.post(`declaration/${declaration.id}/request_new_scan/`)
            .then(() => {
                showNotification({
                    dismissAfter: 4000,
                    message: t('form.notifications.requestScanSuccess'),
                });
            })
    };

    picIndex = (doc) => {
        const { declaration } = this.props

        if (doc) {
            const index = declaration.documentsToDisplay.findIndex(item => item.id === doc.id)
            return index + 1
        }
    }

    color = () => {
        const { declaration } = this.props;
        let status = '#daeaf3'
        if (declaration.documentsToDisplay.length !== 0) {
            declaration.documentsToDisplay.find(document => {
                if (document.status === 'approved by customer service') {
                    status = '#b3ded8'
                } else if (document.status === 'rejected') {
                    status ='#fbdba7'
                }

            })

        }
        return status
    }

    renderPicChangeMenu = () => {
        const { declaration } = this.props;
        return (
            <ThertriaryWrapper>
                <Menu pagination size="mini">
                    <Menu.Item icon as="a" onClick={()=> this.handleSelect('left')}><Icon name="chevron left" /></Menu.Item>
                    {(declaration.documentsToDisplay.length !== 0 && this.selectedDocument) && <Menu.Item>{this.picIndex(this.selectedDocument)} / {declaration.documentsToDisplay.length}</Menu.Item>}
                    <Menu.Item icon as="a" onClick={()=> this.handleSelect('right')}><Icon name="chevron right" /></Menu.Item>
                </Menu>
            </ThertriaryWrapper>
        )
    }

    renderNewPic = () => {
        const { declaration, groupedDeclarationStore } = this.props
        const wasScanRequested = declaration.documents?.wasScanRequestSent('declaration');
        let groupRender = [];

        if (groupedDeclarationStore) {
            groupRender = groupedDeclarationStore.map((dec) => {
                const isSelected = this.props.declaration.id === dec.id
                const isSelectedDeclaration = () => {
                    if (isSelected) {
                        return '#f5f5f5'
                    }
                    return 'white'
                }

                return (
                    <StyledTableRow>
                        <StyledTableCell style={{ backgroundColor: isSelectedDeclaration(), display: 'flex', justifyContent: 'right', alignItems: 'right', textAlign: 'right'}}>
                            <Form.Field>
                                {isSelected && <Modal
                                    open={this.isOpen}
                                    size="fullscreen"
                                    centered={false}
                                    trigger={(
                                        <Form.Field>
                                            <div style={{ display: 'flex'}}>
                                                <MainFileViewWrapper>
                                                    <IconButton
                                                        link
                                                        name="download"
                                                        href={`${process.env.REACT_APP_CY_FRONTEND_API_BASE_URL}declaration/${declaration.id}/all_documents/`}
                                                        title={t('activity.field.requiredDocuments.downloadAll')}
                                                    />
                                                    {!wasScanRequested && (
                                                        <Button onClick={this.requestScan} disabled={declaration.rejectedOrApproved}>
                                                            {t('planning.activityFinalize.requestScan')}
                                                        </Button>
                                                    )}
                                                    <div style={{ display: 'flex' }}>
                                                        {(declaration.documentsToDisplay.length !== 0 && this.selectedDocument) ?
                                                            declaration.documentsToDisplay.map((doc, index) => this.renderNewImage(doc, index)) : ''}
                                                    </div>
                                                    <UploadImageButton data-test-upload-document-modal custom='+'
                                                        onDrop={this.uploadImage}
                                                        accept='image/jpeg, image/png, application/pdf'
                                                    ></UploadImageButton>
                                                </MainFileViewWrapper>
                                            </div>
                                        </Form.Field>
                                    )}
                                >
                                {this.renderModalContent()}
                                </Modal>}
                            </Form.Field>
                        </StyledTableCell>
                    </StyledTableRow>
                )
            })
        }
        let singleNewRender = null;
        singleNewRender = (
            <StyledTableRow>
                <StyledTableCell style={{ display: 'flex', justifyContent: 'right', alignItems: 'right', textAlign: 'right' }}>
                    <Form.Field>
                        <Modal
                            open={this.isOpen}
                            size="fullscreen"
                            centered={false}
                            trigger={(
                                <Form.Field>
                                    <div style={{ display: 'flex'}}>
                                        <MainFileViewWrapper>
                                            <IconButton
                                                link
                                                name="download"
                                                href={`${process.env.REACT_APP_CY_FRONTEND_API_BASE_URL}declaration/${declaration.id}/all_documents/`}
                                                title={t('activity.field.requiredDocuments.downloadAll')}
                                            />
                                            {!wasScanRequested && (
                                                <Button onClick={this.requestScan} disabled={declaration.rejectedOrApproved}>
                                                    {t('planning.activityFinalize.requestScan')}
                                                </Button>
                                            )}
                                            <div style={{ display: 'flex' }}>
                                                {(declaration.documentsToDisplay.length !== 0 && this.selectedDocument) ?
                                                    declaration.documentsToDisplay.map((doc, index) => this.renderNewImage(doc, index)) : ''}
                                            </div>
                                            <UploadImageButton data-test-upload-document-modal custom='+'
                                                onDrop={this.uploadImage}
                                                accept='image/jpeg, image/png, application/pdf'
                                            ></UploadImageButton>
                                        </MainFileViewWrapper>
                                    </div>
                                </Form.Field>
                            )}
                        >
                        {this.renderModalContent()}
                        </Modal>
                    </Form.Field>
                </StyledTableCell>
            </StyledTableRow>
        )

        if (groupRender.length) {
            return groupRender
        }

        return singleNewRender
    }

    renderModalContent = () => {
        const { declaration } = this.props;
        return (
            <React.Fragment>
                <Modal.Header>
                    <Wrapper>
                        {t('declaration.declarationDocumentEdit')}
                        <RightDivider />
                        {t('declaration.lastUpdated')}
                        {declaration.updatedAt && declaration.updatedAt.format(DATETIME_FORMAT)}
                    </Wrapper>
                </Modal.Header>
                <Modal.Content>
                        <React.Fragment>
                            <Table celled style={{ backgroundColor: this.color() }}>
                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell>
                                            {declaration.transactionDatetime ? declaration.transactionDatetime.format(DATE_SHORT_FORMAT) : '-' }
                                        </Table.Cell>
                                        <Table.Cell>
                                            {declaration.paymentMethod ? declaration.paymentMethod : '-' }
                                        </Table.Cell>
                                        <Table.Cell>
                                            {this.declarationStatus(declaration)}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {declaration.fuelCost.id ? declaration.fuelCost.truck.licensePlate : declaration.roadCost.truck.licensePlate} / {declaration.driver.name}
                                        </Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                            <Grid columns={2}>
                                <Grid.Column>
                                    {this.selectedDocument && <TargetTextInput fluid
                                        label='Remark'
                                        target={this.selectedDocument}
                                        name='rejectReason'
                                    />}
                                    <Table celled>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell colSpan='2' >
                                                    {t('declaration.details')}
                                                </Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                            <Table.Row>
                                                <Table.Cell collapsing>
                                                    {t('declaration.type')}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {declaration.fuelCost.id ? declaration.fuelCost.costProduct.description : declaration.roadCost.costProduct.description}
                                                </Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell>
                                                    {t('declaration.date')}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {declaration.transactionDatetime && declaration.transactionDatetime.format(DATETIME_FORMAT)}
                                                </Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell>
                                                    {t('declaration.location')}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {declaration.fuelCost.id ? declaration.fuelCost.location.address : declaration.roadCost.location.address}
                                                </Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell>
                                                    {t('declaration.approved')}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {declaration.approvedAt ? 'Yes' : 'No'}
                                                </Table.Cell>
                                            </Table.Row>
                                        </Table.Body>
                                    </Table>
                                </Grid.Column>
                                <Grid.Column>
                                    <Form.Field>
                                        <PrimaryWrapper>
                                            {this.renderImage()}
                                        </PrimaryWrapper>
                                    </Form.Field>
                                </Grid.Column>
                            </Grid>
                        </React.Fragment>
                    {/* ))} */}

                </Modal.Content>
                <Modal.Actions>
                    <CancelButton data-test-cancel onClick={this.close} />
                    <RightDivider />
                    <React.Fragment>
                        <ItemButton primary
                            icon="sync"
                            disabled={this.isCropping}
                            onClick={() => this.handleReset()}
                        />
                        <ItemButton primary
                            icon="undo"
                            disabled={this.isCropping}
                            onClick={() => this.handleRotate()}
                        />
                        <ItemButton primary
                            icon="circle outline"
                            disabled={this.isCropping}
                            onClick={() => this.handleBrighten(1.1)}
                        />
                        <ItemButton primary
                            icon="circle"
                            disabled={this.isCropping}
                            onClick={() => this.handleBrighten(0.9)}
                        />
                        <ItemButton primary
                            icon="chess board"
                            disabled={this.isCropping}
                            onClick={() => this.handleSharpen(0.5)}
                        />
                        <ItemButton primary
                            icon="crop"
                            onClick={() => this.handleCrop()}
                        />
                    </React.Fragment>
                    <SaveButton data-test-reject-button color='red'
                        onClick={()=>{
                            if (this.selectedDocument) {
                                this.selectedDocument.rejectByEmployee();
                                this.close();
                            }
                        }}
                        content={t('declaration.reject')}
                        disabled={declaration.rejectedOrApproved}
                    />
                    <SaveButton data-test-approve-button primary
                        onClick={()=>{
                            if (this.selectedDocument) {
                                this.selectedDocument.approve();
                                this.close();
                            }
                        }}
                        content={t('declaration.approve')}
                        disabled={declaration.rejectedOrApproved}
                    />
                </Modal.Actions>
            </React.Fragment>
        )
    }

    displayCurrentDeclarationResults() {
        const { declaration } = this.props;

        return (declaration && this.aiResultAvailable && this.ocrDeclaration === declaration.id)
    }

    renderTypeCheck(description) {
        const { declaration } = this.props;

        if (this.typeMatch && !declaration.aiCostProductSelected) {
            return <span style={{fontSize: '15px'}}>{declaration.costProduct.description}<TypeCheck data-test-type-match name='check circle' style={{ color: declaration.rejectedOrApproved ? AFTER_CORRECT_COLOR : BEFORE_CORRECT_COLOR}}/></span>
        }

        if (this.displayCurrentDeclarationResults()) {
            return (
                <Label.Group>
                    <StyledTypeSpan active={this.selectedTypeOption === 'ai'} missmatched={this.missmatchedAiType} >{this.missmatchedAiType ? 'Empty' : this.aiType}</StyledTypeSpan>
                    <LabelSwitcherLeft
                        data-test-type-switcher-ai
                        active={this.selectedTypeOption === 'ai'}
                        missmatched={this.missmatchedAiType}
                        missmatcheddriver={this.missmatchedDriverType}
                        backcolor={declaration.rejectedOrApproved ? AFTER_ORANGE_COLOR : BEFORE_ORANGE_COLOR}
                        rejectedorapproved={declaration.rejectedOrApproved}
                        onClick={() => this.changeTypeSwitcher(true)}
                    >
                        AI
                    </LabelSwitcherLeft>
                    <LabelSwitcherRight
                        data-test-type-switcher-user
                        active={this.selectedTypeOption === 'user'}
                        missmatched={this.missmatchedAiType}
                        missmatcheddriver={this.missmatchedDriverType}
                        backcolor={declaration.rejectedOrApproved ? AFTER_ORANGE_COLOR : BEFORE_ORANGE_COLOR}
                        rejectedorapproved={declaration.rejectedOrApproved}
                        onClick={() => this.changeTypeSwitcher(true)}
                    >
                        <Icon name="user"/>
                    </LabelSwitcherRight>
                    <StyledTypeSpan active={this.selectedTypeOption === 'user'} missmatched={this.missmatchedDriverType} >{this.missmatchedDriverType ? 'Empty' : description}</StyledTypeSpan>
              </Label.Group>
            )
        } else {
            return <span style={{fontSize: '15px'}}>{declaration.costProduct.description}</span>
        }
    };

    renderAmountCheck(amount) {
        const { declaration } = this.props;

        if (this.amountMatch && this.ocrDeclaration === declaration.id) {
            return <span style={{fontSize: '15px'}}>{formatMoney(amount)}<TypeCheck data-test-amount-match name='check circle' style={{ color: declaration.rejectedOrApproved ? AFTER_CORRECT_COLOR : BEFORE_CORRECT_COLOR}}/></span>
        }

        if (this.displayCurrentDeclarationResults()) {
            return (
                <Label.Group>
                    <StyledTypeSpan active={this.selectedAmountOption === 'ai'}>{formatMoney(this.aiAmount, 2, this.ocrCurrencyCode)}</StyledTypeSpan>
                        <LabelSwitcherLeft
                            data-test-amount-switcher-ai
                            active={this.selectedAmountOption === 'ai'}
                            missmatched={this.missmatchedAiAmount}
                            missmatcheddriver={this.missmatchedDriverAmount}
                            backcolor={declaration.rejectedOrApproved ? AFTER_ORANGE_COLOR : BEFORE_ORANGE_COLOR}
                            rejectedorapproved={declaration.rejectedOrApproved}
                            onClick={() => this.changeAmountSwitcher(true)}
                        >
                            AI
                        </LabelSwitcherLeft>
                        <LabelSwitcherRight
                            data-test-amount-switcher-user
                            active={this.selectedAmountOption === 'user'}
                            missmatched={this.missmatchedAiAmount}
                            missmatcheddriver={this.missmatchedDriverAmount}
                            backcolor={declaration.rejectedOrApproved ? AFTER_ORANGE_COLOR : BEFORE_ORANGE_COLOR}
                            rejectedorapproved={declaration.rejectedOrApproved}
                            onClick={() => this.changeAmountSwitcher(true)}
                        >
                            <Icon name="user"/>
                        </LabelSwitcherRight>
                    <StyledTypeSpan active={this.selectedAmountOption === 'user'} missmatched={this.missmatchedDriverAmount}>{this.missmatchedDriverAmount ? 'Empty' : formatMoney(amount)}</StyledTypeSpan>
                </Label.Group>
            );
        } else {
            return (
                <span>
                    {formatMoney(amount)}
                </span>
            )
        }
    };

    renderAiCheckLabel(declaration) {
        if (this.ocrDeclaration === declaration.id) {
            if (this.similarDeclaration && this.similarDriver) {
                return (
                    <React.Fragment>
                        <Label data-test-check-label style={{backgroundColor: SIMILAR_COLOR, color: 'white'}}>
                            {`Driver #${this.similarDriver} Reused photo in Declaration #${this.similarDeclaration}`}
                        </Label>
                        <Button data-test-show size='mini' style={{marginLeft: '10px'}}
                            href={`/driver-care/manager?${urlEncodeObject({
                                '.data_external_id': this.similarDriver,
                            })}`}
                            target="_blank"
                        > Show
                        </Button>
                    </React.Fragment>
                )
            }

            if (this.missmatchedAiType) {
                return <Label data-test-check-label style={{backgroundColor: MISMATCHED_COLOR, color: 'white'}}>No AI Answer Received</Label>
            }

            if (this.missmatchedAiAmount) {
                return <Label data-test-check-label style={{backgroundColor: MISMATCHED_COLOR, color: 'white'}}>Mismatched Amount</Label>
            }

            if ((!declaration.oldCostProduct || !declaration.oldQuantity) && (declaration.aiCostProductSelected || declaration.aiQuantitySelected)) {
                return <Label data-test-check-label style={{backgroundColor: MISMATCHED_COLOR, color: 'white'}}>AI Answer Selected</Label>
            }

            if (!!declaration.costProduct && !declaration.quantity) {
                return <Label data-test-check-label style={{backgroundColor: MISMATCHED_COLOR, color: 'white'}}>No Driver Answer Received</Label>
            }
        }

        if (this.displayCurrentDeclarationResults()) {
            if (this.typeMatch && this.amountMatch) {
                return <Label data-test-check-label style={{backgroundColor: declaration.rejectedOrApproved ? AFTER_CORRECT_COLOR : BEFORE_CORRECT_COLOR, color: 'white'}}>Matching Driver's Answers</Label>
            } else if (declaration.rejectedOrApproved && (declaration.aiCostProductSelected || declaration.aiQuantitySelected)) {
                return <Label data-test-check-label style={{ backgroundColor: AFTER_ORANGE_COLOR, color: 'white'}}>AI Answer Selected</Label>
            } else if (declaration.rejectedOrApproved && !(declaration.aiCostProductSelected || declaration.aiQuantitySelected)) {
                return <Label data-test-check-label style={{ backgroundColor: AFTER_ORANGE_COLOR, color: 'white'}}>Driver Answer Selected</Label>
            }

            return <Label data-test-check-label style={{ backgroundColor: declaration.rejectedOrApproved ? AFTER_ORANGE_COLOR : BEFORE_ORANGE_COLOR, color: 'white'}}>AI Answer Different than Driver's</Label>
        } else {
            return <span></span>
        }
    }

    extractProperDescription(declaration) {
        if (declaration.aiAvailable && declaration.aiCostProductSelected && declaration.oldCostProduct && declaration.oldCostProduct.id) {
            return declaration.oldCostProduct.description
        } else if (declaration.costProduct.id) {
            return declaration.costProduct.description
        } else {
            return null
        }
    }

    renderType = () => {
        const { groupedDeclarationStore } = this.props;
        let types = [];

        if (groupedDeclarationStore) {
            types = groupedDeclarationStore.map((declaration) => {
                const isSelectedDeclaration = () => {
                    if (this.props.declaration.id === declaration.id) {
                        return '#f5f5f5';
                    }
                    return 'white';
                }
                const description = this.extractProperDescription(declaration)

                if (this.props.declaration.id === declaration.id) {
                    return (<StyledTableRow style={{ backgroundColor: isSelectedDeclaration() }}>
                                <StyledTableCell verticalAlign='top' style={{textAlign: 'left'}}>
                                    {this.isLoadingAiResults ? <StyledLoader active inline/> : this.renderTypeCheck(description)}
                                </StyledTableCell>
                                <StyledTableCell verticalAlign='top' style={{textAlign: 'left'}}>
                                    {this.isLoadingAiResults ? <StyledLoader active inline/> : <div data-test-amount={declaration.quantity} style={{display: '-webkit-inline-box'}}>
                                        {this.renderAmountCheck(declaration.quantity)}{this.renderExchandeDiffPopup(declaration)}
                                    </div>
                                    }
                                </StyledTableCell>
                                <StyledTableCell verticalAlign='top' style={{textAlign: 'left'}}>
                                    {this.isLoadingAiResults ? <StyledLoader active inline/> : <div data-test-ai-check={declaration.id}>
                                        {this.renderAiCheckLabel(declaration)}
                                    </div>}
                                </StyledTableCell>
                            </StyledTableRow>)
                } else {
                    return (<StyledTableRow style={{ backgroundColor: isSelectedDeclaration() }}>
                                <StyledTableCell verticalAlign='top' style={{textAlign: 'left'}}>
                                    {declaration.costProduct.description}
                                </StyledTableCell>
                                <StyledTableCell verticalAlign='top' style={{textAlign: 'left'}}>
                                    <div data-test-amount={declaration.quantity}>
                                        {formatMoney(declaration.quantity)}{this.renderExchandeDiffPopup(declaration)}
                                    </div>
                                </StyledTableCell>
                                <StyledTableCell>
                                </StyledTableCell>
                            </StyledTableRow>)
                }
            })
        }

        let type = null;
        const newDescription = this.extractProperDescription(this.props.declaration);
        type = (
            <StyledTableRow>
                <StyledTableCell style={{textAlign: 'left'}}>{newDescription}</StyledTableCell>
                <StyledTableCell style={{textAlign: 'left'}}><div>{formatMoney(this.props.declaration.quantity)} {this.renderExchandeDiffPopup(this.props.declaration)}</div></StyledTableCell>
                <StyledTableCell></StyledTableCell>
            </StyledTableRow>
        )

        if (types.length !== 0) {
            return types;
        }

        return type
    }

    @observable test = null

    render() {
        const { declaration } = this.props

        return (
            <React.Fragment>
                <Form.Group widths="equal">
                    <TargetMoneyInput fluid
                        label='Original Transaction amount'
                        target={declaration}
                        name={'totalQuantity'}
                        disabled={declaration.creator === 'simpled'}
                    />
                    <TargetTextInput fluid
                        label='Simpledcard number'
                        target={declaration.simpledTransaction}
                        name={'publicTokenString'}
                    />
                    <TargetTextInput fluid
                        label='Payment reference'
                        target={declaration.simpledTransaction}
                        name={'description'}
                    />
                </Form.Group>
                <Form.Group widths="equal">
                </Form.Group>
                <Form.Group widths="equal">
                    <Form.Field width={15} style={{ paddingRight: 0 }}>
                        <Table textAlign='center' style={{ borderRight: 0, borderTopRightRadius: 0, borderBottomRightRadius: 0 }}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell style={{textAlign: 'left'}}>
                                        {t('declaration.type')}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell style={{textAlign: 'left'}}>
                                        {t('declaration.amount')}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell style={{textAlign: 'left'}}>
                                        {this.aiResultAvailable && <React.Fragment>{t('declaration.aiCheck')}</React.Fragment>}
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {this.renderType()}
                            </Table.Body>
                        </Table>
                    </Form.Field>
                    <Form.Field width={15} style={{ paddingLeft: 0 }}>
                        <Table textAlign='center' style={{ borderLeft: 0, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell style={{textAlign: 'right', paddingRight: '20px'}}>
                                        {t('declaration.files')}
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {this.renderNewPic()}
                            </Table.Body>
                        </Table>
                    </Form.Field>
                    <Form.Field width={6}>
                        <TargetTextArea fluid style={{ minHeight: 88.5, backgroundColor:'#ffffff', color:'grey' }}
                            target={declaration}
                            name="remark"
                            label='Driver Remark'
                            readOnly
                        />
                        <TargetTextArea fluid style={{ minHeight: 88.5 }}
                            target={declaration}
                            name="officeRemark"
                            label='Office Remark'
                        />
                        {declaration._history &&
                        <Radio toggle data-test-toggle-history-button
                            label={t('wage.toggleSystemHistory')}
                            onChange={() => this.toggleSystemHistory()}
                        />}
                    </Form.Field>
                </Form.Group>
                {this.showSystemHistory && this.renderHistory()}
            </React.Fragment>
        )
    }
}
