import { Table, Icon, Label, Loader } from 'semantic-ui-react';
import { FilePreview } from '@code-yellow/spider';
import styled from 'styled-components';
import DroppableButton from 'component/DroppableButton';


export const LabelSwitcherRight = styled(Label)`
    width: 35px;
    text-align: center !important;
    cursor: pointer !important;
    
    ${props => props.active && `
        color: white !important;
        width: 30px !important;
        pointer-events: none !important;
    `}

    ${props => props.backcolor && props.active && `
        background-color: ${props.backcolor} !important;
    `}

    ${props => props.missmatched && `
        background-color:rgb(87, 87, 86) !important;
    `}

    ${props => props.missmatcheddriver && `
        opacity: 0.6 !important;
        pointer-events: none !important;
        cursor: none !important;
    `}

    ${props => props.rejectedorapproved && `
        pointer-events: none !important;
        cursor: none !important;
    `}
`;

export const StyledLoader = styled(Loader)`
    :before {
        border-color:rgb(119, 115, 115) !important;
    }
`

export const LabelSwitcherLeft = styled(Label)`
    width: 35px;
    text-align: center !important;
    margin-left: 5px !important;
    margin-right: 0px !important;
    font-weight: 900 !important;
    cursor: pointer !important;

    ${props => props.active && `
        color: white !important;
        width: 30px !important;
        pointer-events: none !important;
    `}

    ${props => props.backcolor && props.active && `
        background-color: ${props.backcolor} !important;
    `}

    ${props => props.missmatched && `
        opacity: 0.6 !important;
        pointer-events: none !important;
        cursor: none !important;
    `}

    ${props => props.missmatcheddriver && `
        background-color:rgb(87, 87, 86) !important;
    `}

    ${props => props.rejectedorapproved && `
        pointer-events: none !important;
        cursor: none !important;
    `}
`;

export const StyledTypeSpan = styled.span`
    font-size: 14px !important;
    font-color: #202020;
    
    ${props => !props.active && `
        opacity: 0.5 !important;
        font-size: 12px !important;
        color: gray !important;
        text-decoration: line-through;
    `}

    ${props => props.missmatched && `
        opacity: 0.8 !important;
        text-decoration: none !important;
    `}
`;

export const TypeCheck = styled(Icon)`
    padding-left: 10px !important;
`;

export const StyledFilePreview = styled(FilePreview)`
    height: 75px;
    width: 75px;
    scale: 0.7;
    margin-top: -16px;
    margin-left: -9px;
`;

export const SingleFileBox = styled.div`
    position: relative;
    width: 60px;
    height: 60px;
    margin-right: 5px;
    border: 0.2px solid #d4d4d5;
    border-radius: 5px;
    background: white;
    cursor: pointer;
    padding-left: -2px;
`;

export const SimilarBorder = styled.div`
    position: absolute;
    top: 12px;
    left: 13px;
    width: 32px;
    height: 32px;
    background-color: rgb(201, 51, 51);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    clip-path: polygon(50% 0%, 100% 100%, 0% 100%);
    filter: blur(0.5px);
`;

export const SimilarSign = styled.span`
    color: white;
    font-weight: bold;
    font-size: 20px;
    position: relative;
    top: 5px;
`;

export const UploadImageButton = styled(DroppableButton)`
    width: 55px !important;
    height: 55px !important;
    border: 3px dashed #888 !important;
    background-color: transparent !important;
    color: #424242 !important;
    font-size: 40px !important;
    font-weight: 300 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 6px !important;
    cursor: pointer !important;
    padding: 0 !important;
    box-sizing: border-box !important;
    line-height: 1 !important;
`;

export const StyledTableRow = styled(Table.Row)`
    height: 66px !important;
`;

export const StyledTableCell = styled(Table.Cell)`
    align-content: center !important;
`;

export const PrimaryWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
`;

export const SecondaryWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 400px;
    min-width: 400px;
    max-height: 44px;
    min-height: 44px;
`;

export const MainFileViewWrapper = styled.div`
    display: flex;
    justify-content: right;
    align-items: center;
    max-width: 900px;
    min-width: 400px;
    max-height: 44px;
    min-height: 44px;

`;

export const ThertriaryWrapper = styled.div`
    max-width: '5px';
    justify-content: center;
`;

export const PicAndMenuContainer = styled.div`
    justify-content: center;
    flex-direction: column;
    display: flex;
    max-height: 150px;
    min-height: 150px;
    width: auto;
`;

export const PicAndMenuContainerModal = styled.div`
    justify-content: space-between;
    flex-direction: column;
    display: flex;
    max-height: 600px;
    min-height: 150px;
    width: auto;
`;

export const Wrapper = styled.div`
    display: flex;
`;
