import { observable, computed } from 'mobx';
import { Model, Store, Casts } from 'store/Base';
import { LocationWithoutPoint } from './Location';
import { User, UserStore } from './User';
import { Entity } from './Entity';
import { CustomerStore } from './Customer';
import { DriverLanguageStore } from './DriverLanguage';
import { DriverStatusChange, DriverStatusChangeStore, STATUS_STUDENT, STATUS_IN_PROGRESS, STATUS_INACTIVE } from './Driver/StatusChange';
import { DriverExperienceStore } from './DriverExperience';
import { DriverCertificationStore } from './Certification';
import { DriverAvailability, DriverAvailabilityStore } from './DriverAvailability';
import { DriverAvailabilityDefault } from './Driver/AvailabilityDefault';
import { DriverComment, DriverCommentStore } from './Driver/Comment';
import { TachoEvent, TachoEventStore } from './TachoEvent';
import { storeWithHistory } from './History';
import { LMSUser } from './LMSUser';
import { PayrollCompany } from './PayrollCompany';
import moment from 'moment';
import { debounce, without } from 'lodash';
import { ACTION_DELAY } from 'helpers';
import axios from 'axios';
import { BonusStore } from './Bonus';
import { WageStore } from './Wage';
import { DietDayStore } from './DietDay';
import { AssignmentStore } from './Assignment';
import { DriverWithStudentStore } from './DriverWithStudent';
import { DriverInContractStore } from './DriverInContract';
import { DriverInCountryStore } from './DriverInCountry';
import { DriverCalculationRuleCountrySalaryStore } from './DriverCalculationRuleCountrySalary';
import { DriverCalculationRuleDescriptionSalaryStore } from './DriverCalculationRuleDescriptionSalary';
import { DriverCalculationRuleContractTypeSalaryStore } from './DriverCalculationRuleContractTypeSalary';
import { DriverCalculationRuleStudentSalaryStore } from './DriverCalculationRuleStudentSalary';
import { DriverTransactionCard } from './DriverTransactionCard';
import { DeclarationStore } from './Declaration';
import { Activity } from './Activity';
// {duplicate-with-frontend}
export const MAX_DAYS_WORK_15_HOURS = 3;
export const MAX_EXTENDED_SHIFTS_IN_WEEK = 2;


export function weekendBreakHoursForDoubleTeam(driver1, driver2) {
    if (!driver2 || !driver2.id) {
        return driver1.weekendBreakHours;
    }

    return Math.max(driver1.weekendBreakHours, driver2.weekendBreakHours);
}

class EmergencyContact extends Model {
    static backendResourceName = 'emergency_contact';

    @observable id = null;
    @observable name = '';
    @observable relationToDriver = '';
    @observable landline = '';
    @observable mobile = '';
    @observable doctorName = '';
    @observable doctorPhone = '';

    relations() {
        return {
            location: LocationWithoutPoint,
        };
    }
}

export class Driver extends Model {
    static backendResourceName = 'driver';

    @observable id = null;
    @observable dataExternalId = null;
    @observable name = '';
    @observable avatar = null;
    @observable iban = null;
    @observable ssn = '';
    @observable landline = '';
    @observable landline2 = '';
    @observable mobile = '';
    @observable mobileRemarks = '';
    @observable nationality = '';
    @observable dateOfBirth = null;
    @observable salaryIssue = false;
    // @observable dateOfEmployment = null;
    // @observable exit = false;
    // @observable reasonOfExit = '';
    @observable markedColors = [];

    // Details for planner
    @observable dueHomeDate = null;

    // Current status
    @observable dayStart = null;
    @observable dayDrivingTime = null;
    @observable weekDrivingTime = null;
    @observable previousWeekDrivingTime = null;
    @observable reducedRestsInWeek = 0;
    @observable extendedShiftsInWeek = 0;
    @observable operationalWeekStartedAt = null;
    @observable nextWeekendbreakLength = 'unknown';
    @observable lastShortBreakEnd = null;
    @observable startOfCurrentRest = null;
    @observable weeklyInfoCompiledAt = null;
    @observable email = '';
    // Restrictions
    @observable blockedCountries = [];

    // Working days, those seem reasonable default values
    @observable workDays = 5;
    @observable homeDays = 2;

    @observable initialDriverLevel = 1;

    omitFields() {
        return ['availabilities'];
    }

    @computed get isStudentDriver() {
        return this.currentStatus.status === STATUS_STUDENT;
    }

    @computed get inactive() {
        return this.currentStatus.status === STATUS_INACTIVE;
    }

    @computed get isInProgress() {
        return this.currentStatus.status === STATUS_IN_PROGRESS;
    }

    casts() {
        return {
            dueHomeDate: Casts.date,
            dateOfBirth: Casts.date,
            dateOfEmployment: Casts.date,
            dayDrivingTime: Casts.durationMinutes,
            weekDrivingTime: Casts.durationMinutes,
            previousWeekDrivingTime: Casts.durationMinutes,
            operationalWeekStartedAt: Casts.datetime,
            dayStart: Casts.datetime,
            departureDatetime: Casts.datetime,
            arrivalDatetime: Casts.datetime,
            startOfCurrentRest: Casts.datetime,
            lastShortBreakEnd: Casts.datetime,
            weeklyInfoCompiledAt: Casts.datetime,
        };
    }

    relations() {
        return {
            user: User,
            emergencyContact: EmergencyContact,
            blockedCustomers: CustomerStore,
            languages: DriverLanguageStore,
            driverCertifications: DriverCertificationStore,
            experiences: DriverExperienceStore,
            careManagers: UserStore,
            bonuses: BonusStore,
            wages: WageStore,
            entity: Entity,
            payrollCompany: PayrollCompany,
            statusChanges: DriverStatusChangeStore,
            currentStatus: DriverStatusChange,
            currentComment: DriverComment,
            comments: DriverCommentStore,
            lmsUser: LMSUser,
            currentTachoEvent: TachoEvent,
            currentDriverTransactionCard: DriverTransactionCard,
            declarations: DeclarationStore,
            scheduledHomebaseOrDriverChange: Activity,
            availabilityDefault: DriverAvailabilityDefault,
            currentAvailability: DriverAvailability,
            prevAvailability: DriverAvailability,
            nextAvailability: DriverAvailability,
            availabilities: DriverAvailabilityStore,

            tachoEvents: TachoEventStore,
            dietDays: DietDayStore,
            daysWithStudent: DriverWithStudentStore,
            daysInContract: DriverInContractStore,
            daysInCountry: DriverInCountryStore,

            assignmentsAsDriver1: AssignmentStore,
            assignmentsAsDriver2: AssignmentStore,

            driverCalculationRuleCountrySalaries: DriverCalculationRuleCountrySalaryStore,
            driverCalculationRuleDescriptionSalaries: DriverCalculationRuleDescriptionSalaryStore,
            driverCalculationRuleContractTypeSalaries: DriverCalculationRuleContractTypeSalaryStore,
            driverCalculationRuleStudentSalaries: DriverCalculationRuleStudentSalaryStore,
        };
    }

    @computed
    get weekendBreakHours() {
        if (this.nextWeekendbreakLength === 'short') {
            return 24;
        }
        if (this.nextWeekendbreakLength === 'long') {
            return 45;
        }
        else {
            return '?';
        }
    }


    @computed
    get weekendBreakStart() {
        if (this.operationalWeekStartedAt) {
            return this.operationalWeekStartedAt.clone().add(144, 'hours');
        }
    }

    @computed get lastExternalActivity() {
        if (!this.currentTachoEvent || !this.currentTachoEvent.measuredAt) {
            return null;
        } else {
            return this.currentTachoEvent.measuredAt;
        }
    }

    getPin = (hire) => {
        let driverCertification = null
        if (hire) {
            driverCertification = this.driverCertifications.find(dc => {
                if (dc.id !== null && dc.certification.id === 22) {
                    return dc
                }

            })
        } else if (!hire) {
            driverCertification = this.driverCertifications.find(dc => {
                if (dc.id === null && dc.certification.id === 22) {
                    return dc
                }

            })
        }

        if (driverCertification) {
            return { pin: driverCertification.documentNumber }
        }

        return {}
    }

    setBpPinAsDriversPassword(hire) {
        const pin = this.getPin(hire);
        return this.wrapPendingRequestCount(this.api.post(this.url + 'set_bp_pin_as_drivers_password/', pin));
    }

    calculateDietForStatus() {
        return this.wrapPendingRequestCount(this.api.get(this.url + 'calculate_diet_for_status/'));
    }

    createWage() {
        return this.wrapPendingRequestCount(this.api.get(this.url + 'create_wage/'));
    }

    updateWeeklyInfo() {
        this.wrapPendingRequestCount(this.api.post(this.url + 'update_weekly_info/'));
    }

    // {copy-paste-fe-be-actual-day-driving-time}
    // Actual day driving time includes current tacho status as well
    // as previous driving (which was calculated by the backend).
    // Rounds down.
    actualDayDrivingTime(now = moment()) {
        if (this.dayDrivingTime == null) {
            return null;
        } else if (!this.currentTachoEvent || this.currentTachoEvent.action !== 'drive') {
            return this.dayDrivingTime;
        } else {
            const dayDrivingTimeDuration = moment.duration(this.dayDrivingTime);
            const remainingMinutesAfterCompiledAt = now.clone().startOf('minute').diff(moment(this.weeklyInfoCompiledAt || this.currentTachoEvent.measuredAt).startOf('minute'), 'minutes');

            return dayDrivingTimeDuration.add(remainingMinutesAfterCompiledAt, 'minutes');
        }
    }

    remainingWeekDrivingTime(now = moment()) {
        const remainingMinutesAfterCompiledAt = now.clone().startOf('minute').diff(moment(this.weeklyInfoCompiledAt || this.currentTachoEvent.measuredAt).startOf('minute'), 'minutes');
        if (this.previousWeekDrivingTime == null && this.weekDrivingTime == null) {
            return 0;
        } else if (!this.currentTachoEvent || this.currentTachoEvent.action !== 'drive') {
            if (this.previousWeekDrivingTime < this.weekDrivingTime) {
                return this.previousWeekDrivingTime
            } else if (this.previousWeekDrivingTime > this.weekDrivingTime) {
                return this.weekDrivingTime
            }
        } else {
            if (this.previousWeekDrivingTime < this.weekDrivingTime) {
                const previousWeekDrivingTimeDuration = moment.duration(this.previousWeekDrivingTime);
                return previousWeekDrivingTimeDuration.subtract(remainingMinutesAfterCompiledAt, 'minutes')

            } else if (this.previousWeekDrivingTime > this.weekDrivingTime) {
                const weekDrivingTimeDuration = moment.duration(this.weekDrivingTime);
                return weekDrivingTimeDuration.subtract(remainingMinutesAfterCompiledAt, 'minutes')
            }
        }
    }

    @computed
    get nonExtendedRemainingDayDrivingTime() {
        if (this.dayDrivingTime === null)
            return null;
        else
            return moment.duration(Math.max(0, 9 * 60 - this.actualDayDrivingTime().asMinutes()), 'minutes');
    }

    remainingDayDrivingTime(now = moment()) {
        if (this.dayDrivingTime === null)
            return null;
        else if (this.canDrive10Hours)
            return moment.duration(Math.max(0, 10 * 60 - this.actualDayDrivingTime(now).asMinutes()), 'minutes');
        else
            return moment.duration(Math.max(0, 9 * 60 - this.actualDayDrivingTime(now).asMinutes()), 'minutes');
    }

    @computed
    get age() {
           return moment().diff(this.dateOfBirth, 'years');
    }

    // Hacky way to get the last name. Luckily most drivers are Polish and don't have extra names (ie. ."Alexandr Jufleac")
    @computed
    get lastName() {
        const names = this.name.trim().split(' ');
        // Just remove the first name mwuahaha
        names.shift();
        return names.length > 0 ? names.join(' ') : this.name;
    }

    @computed
    get canWork15Hours() {
        return this.reducedRestsInWeek < MAX_DAYS_WORK_15_HOURS;
    }

    @computed
    get work15HoursDaysRemaining() {
        return MAX_DAYS_WORK_15_HOURS - this.reducedRestsInWeek;
    }

    @computed
    get canDrive10Hours() {
        return this.extendedShiftsInWeek < MAX_EXTENDED_SHIFTS_IN_WEEK;
    }

    @computed
    get expectedRestHours() {
        return this.canWork15Hours ? 9 : 11;
    }

    @computed
    get drive10HoursRemaining() {
        return MAX_EXTENDED_SHIFTS_IN_WEEK - this.extendedShiftsInWeek;
    }

    @computed
    get dayEnd() {
        if (this.dayStart) {
            const hours = this.canWork15Hours ? 15 : 13;
            return this.dayStart.clone().add(hours, 'hours');
        }
    }

    shortBreakStart(now) {
        const lastShortBreakEnd = (this.lastShortBreakEnd || now).clone();
        const nextShortBreakStart = lastShortBreakEnd.clone().add(4.5, 'hours');
        const nextShortBreakIncludeWorkAndDrive = lastShortBreakEnd.clone().add(6, 'hours');

        if (this.tachoEvents) {
            const actualDrivingSinceLastShortBreak = this.tachoEvents.driveDurationSeconds(nextShortBreakStart.clone().subtract(4.5, 'hours'), now);
            const actualWorkingAndDrivingSinceLastShortBreak = this.tachoEvents.driveDurationAndWorkingSeconds(nextShortBreakIncludeWorkAndDrive.clone().subtract(6, 'hours'), now);

            const potentialDrivingSinceLastShortBreak = now.diff(lastShortBreakEnd, 'seconds');   //last short break_end, till now, in seconds

            const maxDrivingTime = 16200; //seconds. 4.5h
            const maxWorkingAndDrivingTime = 21600; //seconds. 6h

            const percentageOfDriving = actualDrivingSinceLastShortBreak *100 / maxDrivingTime;
            const percentageOfWorkingAndDriving= actualWorkingAndDrivingSinceLastShortBreak *100 / maxWorkingAndDrivingTime;

            // Move nextShortBreakStart by the amount of driving time not driven.
            if (potentialDrivingSinceLastShortBreak > 0) {
                nextShortBreakIncludeWorkAndDrive.add(potentialDrivingSinceLastShortBreak - actualWorkingAndDrivingSinceLastShortBreak, 'seconds');
                nextShortBreakStart.add(potentialDrivingSinceLastShortBreak - actualDrivingSinceLastShortBreak, 'seconds');
            }

            if (percentageOfWorkingAndDriving > percentageOfDriving) {
                return nextShortBreakIncludeWorkAndDrive
            }
        }

        return nextShortBreakStart;
    }

    nextBreakStart(now) {
        const dates = [
            this.dayEnd,
            this.weekendBreakStart,
            this.shortBreakStart(now),
        ].filter(Boolean);

        return moment.min(dates);
    }

    @computed
    get nextBreakHours() {
        const start = this.nextBreakStart(moment());
        if (start.isSame(this.weekendBreakStart, 'minute')) {
            return this.weekendBreakHours
                ? `${this.weekendBreakHours}:00h`
                : '- h';
        }
        if (start.isSame(this.dayEnd, 'minute')) {
            return this.canWork15Hours ? '9:00h' : '11:00h';
        }
        if (start.isSame(this.shortBreakStart(moment()), 'minute')) {
            return '0:45h';
        }
        throw Error('nextBreakStart may never be empty, what have you done?');
    }

    autosave() {
        if (this.cancelRequest) {
            this.cancelRequest();
            this.cancelRequest = undefined;
        }

        this.saveDebounced();
    }

    saveDebounced = debounce(() => {
        this.save({
            onlyChanges: true,
            cancelToken: new axios.CancelToken(c => {
                this.cancelRequest = c;
            }),
        });
    }, ACTION_DELAY);
}

export class DriverStore extends Store {
    Model = Driver;
    static backendResourceName = 'driver';

    @observable params = {
        order_by: 'name',
    };

    hasComputedStatusParam(week, status) {
        const computedStatus = this.params['.computed_status'] ? this.params['.computed_status'].split(',') : [];
        const val = `${week}|${status}`;

        return computedStatus.includes(val);
    }

    toggleComputedStatusParam(week, status) {
        let computedStatus = this.params['.computed_status'] ? this.params['.computed_status'].split(',') : [];
        const val = `${week}|${status}`;

        if (computedStatus.includes(val)) {
            computedStatus = without(computedStatus, val);
        } else {
            computedStatus.push(val);
        }

        if (computedStatus.length > 0) {
            this.params['.computed_status'] = computedStatus.join(',');
        } else {
            delete this.params['.computed_status'];
        }

        return computedStatus.includes(val);
    }
}

export class HistoryDriverStore extends storeWithHistory(DriverStore){};
