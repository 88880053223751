import { observable, computed, action } from 'mobx';
import { Model, Store, Casts } from 'store/Base';
import { InvoiceLine, InvoiceLineStore } from './InvoiceLine';
import { InvoiceLineCorrectionStore } from './InvoiceLineCorrection';
import { Tariff } from './Tariff';
import { useActualUuidTag } from './Activity';
import { User } from './User';
import { some, trimEnd } from 'lodash';
import { hasPermission } from 'helpers/permission';
import moment from 'moment';
import { Currency } from 'store/Currency';
import { CsaStore } from 'store/Csa';

export class Invoice extends Model {
    static backendResourceName = 'invoice';

    @observable id = null;
    @observable invoiceNumber = '';
    @observable invoiceDate = null;
    @observable customerReference = '';
    @observable remarks = '';
    @observable clientExpectedAmount = 0;
    @observable exactCode = null;
    @observable createdAt = null;
    @observable updatedAt = null;
    @observable sentAt = null;
    @observable isCredit = false;

    @observable _lastInvoiceNumber = null;

    // Customers can receive a draft invoice, which can be viewed without any
    // authentication. Here we save this uuid which gives unauthenticaed users
    // access.
    @observable _uuid = null;

    relations() {
        return {
            creditOf: Invoice,
            tariff: Tariff,
            lines: InvoiceLineStore,
            line: InvoiceLine,
            corrections: InvoiceLineCorrectionStore,
            createdBy: User,
            currency: Currency,
            csaFiles: CsaStore,
        };
    }

    casts() {
        return {
            createdAt: Casts.datetime,
            invoiceDate: Casts.date,
            sentAt: Casts.datetime,
        };
    }

    convertToCredit() {
        this.setInput('creditOf', this);

        this.id = null;
        this.setInput('sentAt', null);
        this.setInput('isCredit', true);
        this.setInput('invoiceNumber', '');
        this.setInput('invoiceDate', moment());
        this.setInput('clientExpectedAmount', -this.clientExpectedAmount);
        this.lines.forEach(line => line.convertToCredit());
        this.corrections.forEach(line => line.convertToCredit());
    }

    setNextAvailableInvoiceNumber() {
        const invoiceStore = new InvoiceStore();
        const entityId = this.tariff.contract.entity.id;

        invoiceStore.params = {
            order_by: '-invoice_number',
            limit: 1,
            '.tariff.contract.entity': entityId,
            '.invoice_number:startswith': moment().format('YY'),
        };

        return invoiceStore.fetch().then(() => {
            if (invoiceStore.length === 1) {
                const last = invoiceStore.at(0).invoiceNumber;
                const parsed = parseInt(last);

                if (isFinite(parsed) && parsed + '' === last) {
                    this.setInput('invoiceNumber', (parsed + 1) + '');
                }

                this.setInput('_lastInvoiceNumber', last);
            } else {
                this.setInput('invoiceNumber', `${moment().format('YY')}${entityId}000001`);
            }
        });
    }

    @computed
    get immutable() {
        return !!this.sentAt;
    }

    @computed
    get draftPdfUrl() {
        return `${trimEnd(this.api.baseUrl, '/')}${this.url}draft_pdf/${this._uuid}/`;
    }

    @computed
    get draftApprovalForCustomerUrl() {
        return `${window.location.origin}/administration/invoice/${this.id}/approve/{{UUID}}/`;
    }

    draftPdfExists() {
        return this.api.axios.head(`${trimEnd(this.api.baseUrl, '/')}${this.url}draft_pdf/${this._uuid}/`);
    }

    @computed
    get draftPdfTemplateUrl() {
        return `${window.location.origin}${trimEnd(this.api.baseUrl, '/')}${this.url}draft_pdf/{{UUID}}/`;
    }

    @computed
    get draftPdfDownloadUrl() {
        return `${this.draftPdfUrl}?download=true`;
    }

    @computed
    get isDisputed() {
        const invoicedAmount = Math.round(this.amount - this.customAmount - this.correctionAmount);
        const expectedAmount = Math.round(this.expectedActivityAmount);

        return invoicedAmount !== expectedAmount;
    }

    @computed
    get amount() {
        let amount = 0;

        this.lines.forEach(line => {
            let addition = line.amount;

            if (this.tariff.contract.isFc && (this.sentAt || this.isCredit)) {
                addition = line.amountFc;
            }

            amount += addition;
        });

        this.corrections.forEach(correction => {
            amount += this.tariff.contract.isFc ? correction.invoicedAmountFc : correction.invoicedAmount;
        });

        return amount;
    }

    // {copy-pasta-activity-ids}
    @computed
    get activityIds() {
        const ids = [];

        this.lines.forEach(line =>
            line.items.forEach(item =>
                ids.push(item.activity.id)
            )
        );

        return ids;
    }

    @computed
    get expectedAmount() {
        return (
            this.expectedActivityAmount +
            this.customAmount +
            this.correctionAmount
        );
    }

    @computed
    get canFinalize() {
        if (this.tariff.contract.selfBilling) {
            return this.amount === this.clientExpectedAmount
        }

        return true;
    }

    @computed
    get expectedActivityAmount() {
        let amount = 0;

        this.lines.forEach(line => {
            if (!line.customLine) {
                amount += line.calcExpectedAmount(this.tariff);
            }
        });

        if (this.isCredit) {
            return -amount;
        }

        return amount;
    }

    @computed
    get activityAmount() {
        let amount = 0;

        this.lines.forEach(line => {
            if (!line.customLine) {
                amount += line.customAmount;
            }
        });

        return amount;
    }

    @computed
    get customAmount() {
        let amount = 0;

        this.lines.forEach(line => {
            if (line.customLine) {
                amount += line.customAmount;
            }
        });

        return amount;
    }

    @computed
    get correctionAmount() {
        let amount = 0;

        this.corrections.forEach(correction => {
            amount += correction.invoicedAmount;
        });

        return amount;
    }

    @computed
    get expectedKmAmount() {
        let amount = 0;

        this.lines.forEach(line => {
            if (line.items.length > 0) {
                amount += line.calcExpectedKmAmount(this.tariff);
            }
        });

        return amount;
    }

    @computed
    get expectedWaitingAmount() {
        let amount = 0;

        this.lines.forEach(line => {
            if (line.items.length > 0) {
                amount += line.calcExpectedWaitingAmount(this.tariff);
            }
        });

        return amount;
    }

    @computed
    get expectedOtherCostsAmount() {
        let amount = 0;

        this.lines.forEach(line => {
            if (line.items.length > 0) {
                amount += line.calcExpectedOtherCostsAmount(this.tariff);
            }
        });

        return amount;
    }

    @computed
    get expectedSecondDriverAmount() {
        let amount = 0;

        this.lines.forEach(line => {
            if (line.items.length > 0) {
                amount += line.calcExpectedSecondDriverAmount(this.tariff);
            }
        });

        return amount;
    }

    @computed
    get kmAmount() {
        let kmAmount = 0;

        this.lines.forEach(line => {
            kmAmount += line.kmAmount;
        });

        return kmAmount;
    }

    @computed
    get kmSurcharge() {
        let kmSurcharge = 0;

        this.lines.forEach(line => {
            kmSurcharge += line.kmSurcharge;
        });

        return kmSurcharge;
    }

    @computed
    get fixedAmount() {
        let fixedAmount = 0;

        this.lines.forEach(line => {
            fixedAmount += line.fixedAmount;
        });

        return fixedAmount;
    }

    @computed
    get fixedSurcharge() {
        let fixedSurcharge = 0;

        this.lines.forEach(line => {
            fixedSurcharge += line.fixedSurcharge;
        });

        return fixedSurcharge;
    }

    @computed
    get weekendAmount() {
        let weekendAmount = 0;

        this.lines.forEach(line => {
            weekendAmount += line.weekendAmount;
        });

        return weekendAmount;
    }

    @computed
    get waitingAmount() {
        let waitingAmount = 0;

        this.lines.forEach(line => {
            waitingAmount += line.waitingAmount;
        });

        return waitingAmount;
    }

    @computed
    get secondDriverAmount() {
        let secondDriverAmount = 0;

        this.lines.forEach(line => {
            secondDriverAmount += line.secondDriverAmount;
        });

        return secondDriverAmount;
    }

    @computed
    get invoicedKm() {
        let invoicedKm = 0;

        this.lines.forEach(line => {
            invoicedKm += line.invoicedKm;
        });

        return invoicedKm;
    }

    @computed
    get invoicedTollAmount() {
        let invoicedTollAmount = 0;

        this.lines.forEach(line => {
            invoicedTollAmount += line.tollAmount;
        });

        return invoicedTollAmount;
    }

    @computed
    get invoicedOtherCostsAmount() {
        let invoicedOtherCostsAmount = 0;

        this.lines.forEach(line => {
            invoicedOtherCostsAmount += line.otherCostsAmount;
        });

        return invoicedOtherCostsAmount;
    }

    @computed
    get activityCount() {
        return this.lines.models.reduce(
            (res, line) => res + line.items.length,
            0
        );
    }

    @computed
    get invoiceableEntityFee() {
        return Math.round(this.amount * this.tariff.contract.entityFee / 10000);
    }

    hasActivity(activity) {
        return some(this.lines.models, line => {
            return some(line.items.models, item => {
                return item.activity.id === activity.id;
            });
        });
    }

    @action
    recalculate(tariff) {
        this.lines.forEach(line => {
            line.recalculate(tariff);
        });
    }

    sendDraft(email) {
        if (email !== undefined){
            return this.wrapPendingRequestCount(
                this.api.post(`${this.url}send_draft/`, {
                    to: email.recipients,
                    subject: useActualUuidTag(email.subject),
                    body: useActualUuidTag(email.content),
                })
            );
        }
    }

    approveDraft() {
        return this.wrapPendingRequestCount(
            this.api.post(`${this.url}approve_draft/${this._uuid}/`, {
                customer_reference: this.customerReference
            })
        );
    }

    rejectDraft() {
        return this.wrapPendingRequestCount(
            this.api.post(`${this.url}reject_draft/${this._uuid}/`)
        );
    }

    /**
     * When an invoice is sent, the pdf is frozen and archived. Though already
     * sent to the client, sometimes there are small mistakes, and the pdf can
     * be recreated using new data.
     */
    recreatePdf() {
        return this.wrapPendingRequestCount(
            this.api.post(`${this.url}recreate_pdf/`)
        );
    }

    finalize(email) {
        let data = {};

        if (email !== undefined) {
            data = {
                to: email.recipients,
                subject: useActualUuidTag(email.subject),
                body: useActualUuidTag(email.content),
            };
        }

        if (hasPermission('invoice.manage_invoice:all') && this.__changes.includes('invoiceDate')) {
            data.invoice_date = this.toBackend().invoice_date;
        }

        return this.api.post(`${this.url}send/`, data)
            .then(() => this.sentAt = moment());
    }
}

export class InvoiceStore extends Store {
    Model = Invoice;
    static backendResourceName = 'invoice';

    /**
     * Just before fetching, you can call this function to ensure you only get
     * invoices which are entity-invoiceable.
     */
    setEntityInvoiceableParams(entity) {
        this.params = {};
        this.params['.sent_at:not:isnull'] = '';
        return this.params;
    }
}
